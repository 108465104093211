import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

interface GreetingMessageProps {
	title?: string;
	subtitle?: string;
}

const GreetingMessage: React.FC<GreetingMessageProps> = ({
	title,
	subtitle,
}) => {
	const theme = useTheme();
	return (
		<Box width="100%" display="flex" flexDirection="column" gap={2}>
			{title && (
				<Typography
					variant="h1"
					sx={{
						fontSize: "20px",
						fontWeight: 600,
						lineHeight: "28px",
						letterSpacing: "-0.0026em",
						textAlign: "left",
						color: theme.palette.text.primary,
						textTransform: "capitalize",
					}}
				>
					{title}
				</Typography>
			)}
			{subtitle && (
				<Typography
					sx={{
						fontSize: "14px",
						fontWeight: 600,
						lineHeight: "19px",
						letterSpacing: "-0.0015em",
						textAlign: "left",
						color: theme.palette.text.secondary,
					}}
				>
					{subtitle}
				</Typography>
			)}
		</Box>
	);
};

export default GreetingMessage;
