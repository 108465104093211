import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
	Box,
	Drawer,
	ListItemText,
	Typography,
	Badge,
	Avatar,
	Divider,
} from "@mui/material";
import {
	getAllApplicationTasks,
	updateApplicationTask,
} from "../../../common/services/retool-service";

const KanbanBoard = () => {
	const [tasks, setTasks] = useState([]);
	const [selectedTask, setSelectedTask] = useState(null);

	useEffect(() => {
		const fetchTasks = async () => {
			try {
				const response = await getAllApplicationTasks();
				const fetchedTasks = response.data.data.map((task) => ({
					id: task.id.toString(),
					title: task.name,
					status: task.status.toLowerCase().replace(" ", "-"),
					assignee: task.assignee,
					description: task.description,
					taskType: task.taskType,
				}));
				setTasks(fetchedTasks);
			} catch (error) {
				console.error("Failed to fetch tasks:", error);
			}
		};
		fetchTasks();
	}, []);

	const handleDragEnd = async (result) => {
		if (!result.destination) return;

		const { source, destination } = result;
		const movedTask = tasks[source.index];

		if (source.droppableId !== destination.droppableId) {
			movedTask.status = destination.droppableId;
			try {
				await updateApplicationTask(movedTask.id, {
					name: movedTask.title,
					assignee: `${movedTask.assignee.name} - ${movedTask.assignee.email}`,
					description: movedTask.description,
					taskType: movedTask.taskType,
					status:
						movedTask.status == "InProgress" ? "in-progress" : movedTask.status,
				});
				setTasks((prevTasks) => {
					const updatedTasks = [...prevTasks];
					updatedTasks.splice(source.index, 1);
					updatedTasks.splice(destination.index, 0, movedTask);
					return updatedTasks;
				});
			} catch (error) {
				console.error("Failed to update task status:", error);
			}
		}
	};

	const openTaskDetails = (task) => {
		setSelectedTask(task);
	};

	const closeTaskDetails = () => {
		setSelectedTask(null);
	};

	const taskCount = (status) =>
		tasks.filter((task) => task.status === status).length;

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<Box display="flex" justifyContent="space-around" p={2}>
				{["todo", "in-progress", "completed"].map((status) => (
					<Droppable key={status} droppableId={status}>
						{(provided) => (
							<Box
								{...provided.droppableProps}
								ref={provided.innerRef}
								sx={{
									width: 300,
									minHeight: "80vh",
									backgroundColor: "#f5f5f5",
									padding: 2,
									margin: 1,
									borderRadius: 2,
								}}
							>
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									<Typography variant="h6">
										{status.charAt(0).toUpperCase() + status.slice(1)}
									</Typography>
									<Badge badgeContent={taskCount(status)} color="primary" />
								</Box>
								<Divider sx={{ my: 1 }} />
								{tasks &&
									tasks
										.filter((task) => task.status === status)
										.map((task, index) => (
											<Draggable
												key={task.id}
												draggableId={task.id}
												index={index}
											>
												{(provided) => (
													<Box
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														sx={{
															mb: 2,
															p: 2,
															backgroundColor: "#fff",
															borderRadius: 1,
															boxShadow: 2,
															cursor: "pointer",
														}}
														onClick={() => openTaskDetails(task)}
													>
														<ListItemText primary={task.title} />
														<Box display="flex" alignItems="center" mt={1}>
															<Avatar sx={{ width: 24, height: 24, mr: 1 }} />
															<Typography variant="body2">
																{task.assignee}
															</Typography>
														</Box>
													</Box>
												)}
											</Draggable>
										))}
								{provided.placeholder}
							</Box>
						)}
					</Droppable>
				))}
			</Box>

			<Drawer
				anchor="right"
				open={!!selectedTask}
				onClose={closeTaskDetails}
				sx={{ zIndex: 89999999 }}
			>
				<Box p={2} sx={{ width: 500 }}>
					<Typography variant="h6">{selectedTask?.title}</Typography>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						gap={2}
					>
						<Typography fontWeight={600} variant="body1" mt={2}>
							Task Status:{" "}
							{selectedTask?.status.charAt(0).toUpperCase() +
								selectedTask?.status.slice(1).replace("-", " ")}
						</Typography>
						<Typography fontWeight={600} variant="body1" mt={2}>
							Task Type: {selectedTask?.taskType}
						</Typography>
					</Box>

					<Typography variant="body1" mt={2} lineHeight={3}>
						{selectedTask?.description}
					</Typography>
					<Typography
						variant="body1"
						mt={2}
						backgroundColor="green"
						p={2}
						color="white"
					>
						Assigned to: {selectedTask?.assignee}
					</Typography>
				</Box>
			</Drawer>
		</DragDropContext>
	);
};

export default KanbanBoard;
