import React, { useState } from "react";
import { Modal, Box, Typography, useTheme, IconButton } from "@mui/material";
import { useDropzone } from "react-dropzone";
import CustomTextInput from "../Elements/CustomTextInput";
import CustomButton from "../Elements/CustomButton";
import DeleteIcon from "@mui/icons-material/Delete";
import toast from "react-hot-toast";
import {
	uploadClientDocs,
	uploadDealDocs,
} from "../../../common/services/retool-service";

interface BankStatementUploadModalProps {
	open: boolean;
	onClose: () => void;
	handleRefetch: () => void;
}

const BankStatementUploadModal: React.FC<BankStatementUploadModalProps> = ({
	open,
	onClose,
	handleRefetch,
}) => {
	const theme = useTheme();
	const [bankStatementData, setBankStatementData] = useState({
		email: "",
		description: "",
	});
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"application/pdf": [".pdf"],
			"text/csv": [".csv"],
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
				".xlsx",
			],
		},
		multiple: true,
		onDrop: (acceptedFiles) => {
			setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
		},
	});

	const handleFileRemove = (fileToRemove: File) => {
		setSelectedFiles((prevFiles) =>
			prevFiles.filter((file) => file !== fileToRemove)
		);
	};

	const handleUploadSubmit = async () => {
		setIsLoading(true);

		const filesAsBase64 = await Promise.all(
			selectedFiles.map((file) => {
				return new Promise<string>((resolve, reject) => {
					const reader = new FileReader();
					reader.onloadend = () => {
						resolve(reader.result as string);
					};
					reader.onerror = () => {
						reject(new Error("Failed to convert file to base64"));
					};
					reader.readAsDataURL(file);
				});
			})
		);

		const payload = {
			documentRef: "bank_statement",
			description: bankStatementData.description,
			expiryInMonth: "6",
			docs: selectedFiles.map((file, index) => ({
				base64Data: filesAsBase64[index].split(",")[1],
				name: file.name,
				sizeBytes: file.size,
				type: file.type,
			})),
		};

		try {
			const response = await uploadClientDocs(payload);

			if (response.data.success) {
				toast.success(response.data.message);
				handleRefetch();
			} else {
				toast.error(response.data.message);
			}

			handleUploadDialogClose();
		} catch (error) {
			toast.error("Failed to upload document");
		}

		setIsLoading(false);
	};

	const handleUploadDialogClose = () => {
		setSelectedFiles([]);
		setBankStatementData({
			email: "",
			description: "",
		});
		onClose();
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "600px",
					height: "auto",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "8px",
					border: 0,
					margin: "auto",
					top: "10%",
					position: "relative",
					color: theme.palette.text.primary,
					overflow: "auto",
				}}
			>
				<Typography
					mb={3}
					sx={{
						fontSize: "24px",
						fontWeight: "500",
					}}
				>
					Upload Bank Statements
				</Typography>

				{/* Drag and Drop Section */}
				<Box
					{...getRootProps()}
					sx={{
						border: "2px dashed #cccccc",
						padding: "20px",
						textAlign: "center",
						marginBottom: "20px",
					}}
				>
					<input {...getInputProps()} />
					<Typography variant="body1">
						Drag 'n' drop some files here, or click to select files
					</Typography>
				</Box>

				{/* Selected Files */}
				{selectedFiles.length > 0 && (
					<Box sx={{ marginTop: "10px" }}>
						<strong>Selected Files:</strong>
						<ul>
							{selectedFiles.map((file, index) => (
								<li
									key={index}
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
								>
									{file.name}
									<IconButton
										onClick={() => handleFileRemove(file)}
										size="small"
										sx={{ color: theme.palette.error.main }}
									>
										<DeleteIcon />
									</IconButton>
								</li>
							))}
						</ul>
					</Box>
				)}

				{/* Description Input */}
				<CustomTextInput
					label="Description"
					value={bankStatementData.description}
					onChange={(e) =>
						setBankStatementData((prev) => ({
							...prev,
							description: e.target.value,
						}))
					}
					sx={{ marginTop: "20px" }}
					multiline
					rows={4}
				/>

				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						gap: "10px",
						marginTop: "20px",
					}}
				>
					<CustomButton
						text="Cancel"
						variant="outlined"
						color="secondary"
						onClick={handleUploadDialogClose}
					/>

					<CustomButton
						text="Submit"
						variant="contained"
						onClick={handleUploadSubmit}
						disabled={isLoading || selectedFiles.length === 0} // Disable if loading or no files
					/>
				</Box>
			</Box>
		</Modal>
	);
};

export default BankStatementUploadModal;
