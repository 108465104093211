import React from "react";
import {
	ResponsiveContainer,
	LineChart,
	Line,
	XAxis,
	YAxis,
	Tooltip,
	CartesianGrid,
} from "recharts";
import { Box, CircularProgress, Typography, Alert } from "@mui/material";
import { useCumulativeClientsPerMonth } from "../../../common/helpers/hooks/useFinanceService";
import BaseCard from "../Cards/BaseCard";
import NoDataFoundCard from "../Cards/NoDataFoundCard";

const CumulativeClientsPerMonthChart = () => {
	const { data, isLoading, error } = useCumulativeClientsPerMonth();

	if (isLoading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height={300}
			>
				<CircularProgress color="success" />
			</Box>
		);
	}

	if (error) {
		return (
			<Alert severity="error">
				Failed to load data. Please try again later.
			</Alert>
		);
	}

	if (!data || data?.cumulativeClients?.length === 0) {
		return <NoDataFoundCard title="Cumulative Clients Per Month" />;
	}

	return (
		<BaseCard sx={{ height: "auto", padding: "20px 10px" }}>
			<Typography variant="body2" gutterBottom>
				Cumulative Clients Per Month
			</Typography>
			<ResponsiveContainer width="100%" height={300}>
				<LineChart
					data={data?.cumulativeClients}
					margin={{ top: 20, right: 20, left: 20, bottom: 40 }}
				>
					<XAxis dataKey="month" fontSize={14} angle={-45} textAnchor="end" />
					<YAxis
						dataKey="total"
						fontSize={12}
						label={{
							value: "Number of loans",
							position: "insideLeft",
							angle: -90,
							offset: 10,
							fontSize: 14,
						}}
					/>
					<Tooltip />
					<Line
						type="monotone"
						dataKey="total"
						stroke="#619C9C"
						strokeWidth={2}
					/>
				</LineChart>
			</ResponsiveContainer>
			<Typography variant="body2" gutterBottom>
				Date (Month - Year)
			</Typography>
		</BaseCard>
	);
};

export default CumulativeClientsPerMonthChart;
