import { FC, useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom"; // Import Link from react-router-dom
import { Box, Grid, Typography, Container } from "@mui/material";
import LoginForm from "../../common/components/Forms/LoginForm";
import BaseCard from "../../common/components/Cards/BaseCard";

interface JWTLoginProps {
	email?: string;
}

export const LoginPage: FC<JWTLoginProps> = ({ email }) => {
	const [showPassword, setShowPassword] = useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (isAuthenticated) {
			navigate("/home");
		}
	}, [isAuthenticated, navigate]);

	const handleTogglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	return (
		<>
			<Container
				maxWidth="sm"
				style={{
					position: "absolute",
					left: "50%",
					top: "50%",
					transform: "translate(-50%,-50%)",
				}}
			>
				<BaseCard sx={{ height: "auto", width: "100%", padding: "20px" }}>
					<Grid container spacing={3} className="grid">
						<Grid className="grid__item" item xs={12}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "center",
									mt: 2,
								}}
							>
								{/* Replacing Next.js Image with standard img */}
								<img
									src="/static/pumpkn-logo2.png"
									alt="Pumpkn logo"
									width={80}
									height={80}
								/>
								<Typography
									lineHeight="24.75px"
									fontSize="18px"
									fontWeight="700"
									color="#111827"
									sx={{ mt: 2 }}
									variant="body1"
								>
									Sign In
								</Typography>
								<Typography
									color="textSecondary"
									sx={{ mt: 2 }}
									variant="body1"
								>
									Login to Pumpkn
								</Typography>
							</Box>
						</Grid>

						{/* Login Form */}
						<Grid className="grid__item" item xs={12}>
							<Box sx={{ flexGrow: 1, mt: 2 }}>
								<LoginForm
									email={email}
									isLoading={isLoading}
									showPassword={showPassword}
									handleTogglePasswordVisibility={
										handleTogglePasswordVisibility
									}
								/>
							</Box>
						</Grid>

						<Grid className="grid__item" item xs={12}>
							<Box display="flex" justifyContent="center">
								<Box display="flex" flexDirection="column" alignItems="center">
									{/* Use RouterLink for navigation */}
									<RouterLink
										to="/authentication/forgot-password"
										style={{ textDecoration: "none" }}
									>
										<Typography color="textSecondary" variant="body2">
											Forgot password
										</Typography>
									</RouterLink>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</BaseCard>
			</Container>
		</>
	);
};

export default LoginPage;
