import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import GreetingMessage from "../../common/components/Headings/GreetingMessage";
import BaseCard from "../../common/components/Cards/BaseCard";
import {
	AddCircle,
	Delete,
	Flag,
	Link,
	LockClock,
	People,
	ManageSearchOutlined,
	PushPin,
	Refresh,
	Visibility,
} from "@mui/icons-material";
import CustomTable from "../../common/components/Tables/CustomTable";

import transactionsDummyData from "../../data/transactions";
import { CircularProgress, Grid, Tooltip, useTheme } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import IconWithText from "../../common/components/Elements/IconWithText";
import { useLocation, useNavigate } from "react-router-dom";
import ResponsiveGrid from "../../common/components/Grids/ResponsiveGrid";
import CardWithIcon from "../../common/components/Elements/CardWithIcon";
import {
	useFetchClientAndDealSummary,
	useFetchClients,
} from "../../common/helpers/hooks/useClientService";
import CustomSearchBar from "../../common/components/Elements/CustomSearchBar";
import AddClientModal from "../../common/components/Modals/AddClientModal";
import ProfileDetailsModal from "../../common/components/Modals/ProfileDetailsModal";
import { deleteUser } from "../../common/services/retool-service";
import toast from "react-hot-toast";
import ConfirmationModal from "../../common/components/Modals/ConfirmationModal";
import ClientsFloatingActionMenu from "../../common/components/Menus/ClientsFloatingActionMenu";
import CustomButton from "../../common/components/Elements/CustomButton";
import LoadingIconButton from "../../common/components/Elements/LoadingIconButton";
import AddClientModal2 from "../../common/components/Modals/AddClientModal2";
import CustomTabs from "../../common/components/Elements/CustomTabs";
import BackgroundJobTable from "../../common/components/Tables/BackgroudJobTable";
import CV4BReportDetailsModal from "../../common/components/Modals/CV4BReportDetailsModal";
import FundingNeedModal from "../../common/components/Modals/FundingNeedModal";

const ManageClientsPage: React.FC = () => {
	const [openAddClientModal, setOpenAddClientModal] = useState(false);
	const [openViewDetailsModal, setOpenViewDetailsModal] = useState(false);
	const [openViewCV4BReportModal, setOpenViewCV4BReportModal] = useState(false);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(10);
	const [search, setSearch] = useState("");

	const {
		data: clients,
		isLoading: isFetching,
		refetch: refetchClients,
		isRefetching,
	} = useFetchClients({
		page,
		size,
		searchTerm: search,
	});

	const handleFetchNewPage = async (newPage: number) => {
		setPage(newPage);
	};

	const handleSize = async (newSize: number) => {
		setSize(newSize);
	};

	const { data: summary, refetch: refetchSummary } =
		useFetchClientAndDealSummary();

	const [isLoading, setIsLoading] = useState(false);
	const [openAddNewDeal, setOpenAddNewDeal] = useState(false);
	const [selectedRow, setSelectedRow] = useState<any>(null);
	const [selectedRows, setSelectedRows] = useState<any[]>([]);

	const [searchTerm, setSearchTerm] = useState("");
	const navigate = useNavigate();
	const theme = useTheme();

	const tabs = [{ label: "Clients" }, { label: "Jobs" }];
	const [tabIndex, setTabIndex] = useState(0);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};
	const handleSubmitSearch = () => {
		setSearch(searchTerm);
	};
	const refetch = () => {
		refetchClients();
		refetchSummary();
	};

	const [filteredData, setFilteredData] = useState([]);

	useEffect(() => {
		if (clients?.data) {
			setFilteredData(clients?.data);
			setSelectedRows([]);
		}
	}, [clients]);

	const handleView = (row: any) => {
		setSelectedRow(row);
		setOpenViewDetailsModal(true);
	};

	const handleViewCV4BReport = (row: any) => {
		setSelectedRow(row);
		setOpenViewCV4BReportModal(true);
	};

	const handleDelete = (row: any) => {
		setSelectedRow(row);
		setOpenConfirmationModal(true);
		setConfirmationMessage(
			`Are you sure you want to delete this client: ${row?.email}? This action cannot be
					undone. Deleting this client will also remove associated (user profile, business profile, deals, documents, bank statements, offers and contracts.)`
		);
	};

	const handleDeleteClient = async () => {
		try {
			setIsLoading(true);
			const response = await deleteUser(selectedRow?.userId as string);
			if (response.data.success) toast.success(response.data.message);
			else toast.error(response.data.message);
			setIsLoading(false);
			setOpenConfirmationModal(false);
			refetch();
		} catch (error) {
			toast.error("Unable to delete client.");
			setIsLoading(false);
		}
	};

	const location = useLocation();

	useEffect(() => {
		refetch();
	}, [location.pathname]);

	return (
		<Box
			sx={{
				py: 4,
				px: 3,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				textAlign: "center",
				width: "100%",
				gap: 2,
			}}
		>
			<GreetingMessage title="Manage Clients" />

			<ResponsiveGrid columnCount={4}>
				<CardWithIcon
					text="Clients"
					icon={
						<People
							style={{
								color: theme.palette.text.primary,
								marginRight: "5px",
								fontSize: "34px",
							}}
						/>
					}
					label={summary?.data.noOfClients}
					sx={{ cursor: "auto" }}
				/>
				<CardWithIcon
					text="Active Deals"
					icon={
						<LockClock
							style={{
								color: theme.palette.text.primary,
								marginRight: "5px",
								fontSize: "34px",
							}}
						/>
					}
					label={summary?.data.noOfActiveDeals}
					sx={{ cursor: "auto" }}
				/>
				<CardWithIcon
					text="Linked Bank Accounts"
					icon={
						<Link
							style={{
								color: theme.palette.text.primary,
								marginRight: "5px",
								fontSize: "34px",
							}}
						/>
					}
					label={summary?.data.noLinkedBankAccounts}
					sx={{ cursor: "auto" }}
				/>
			</ResponsiveGrid>

			<BaseCard sx={{ height: "auto" }}>
				<Box px={2} py={4}>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
					>
						<GreetingMessage title="Clients" />
						<LoadingIconButton onClick={refetch} isLoading={isRefetching} />
					</Box>

					<>
						<Box
							width="100%"
							my={2}
							gap={2}
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<Box sx={{ flexGrow: 1 }}>
								<CustomSearchBar
									searchTerm={searchTerm}
									onSearchChange={handleSearchChange}
									handleSearch={handleSubmitSearch}
								/>
							</Box>
							<Box sx={{ marginLeft: "auto" }}>
								<CardWithIcon
									icon={
										<AddCircle
											style={{
												color:
													theme.palette.mode == "light"
														? "rgba(73, 69, 79, 1)"
														: "white",
												marginRight: "5px",
												fontSize: "18px",
											}}
										/>
									}
									text="Add Client"
									onClick={() => {
										setOpenAddClientModal(true);
									}}
								/>
							</Box>
							{selectedRows.length == 1 && (
								<Box sx={{ marginLeft: "auto" }}>
									<CardWithIcon
										icon={
											<AddCircle
												style={{
													color:
														theme.palette.mode == "light"
															? "rgba(73, 69, 79, 1)"
															: "white",
													marginRight: "5px",
													fontSize: "18px",
												}}
											/>
										}
										text="Add Deal"
										onClick={() => {
											setOpenAddNewDeal(true);
										}}
									/>
								</Box>
							)}
						</Box>
						<CustomTable
							isLoading={isFetching || isRefetching}
							icons={(client) => [
								{
									icon: (
										<Tooltip title="View Details" arrow>
											<Visibility
												sx={{
													fill:
														theme.palette.mode == "light"
															? "rgba(73, 69, 79, 1)"
															: "white",
													fontSize: "20px",
												}}
											/>
										</Tooltip>
									),
									onClick: () => {
										setSelectedRow(client);
										handleView(client);
									},
								},
								{
									icon: (
										<Tooltip title="View Report" arrow>
											<ManageSearchOutlined
												sx={{
													fill:
														theme.palette.mode == "light"
															? "rgba(73, 69, 79, 1)"
															: "white",
													fontSize: "20px",
												}}
											/>
										</Tooltip>
									),
									onClick: () => {
										setSelectedRow(client);
										handleViewCV4BReport(client);
									},
								},
								{
									icon: (
										<Tooltip title="Delete Client" arrow>
											<Delete
												sx={{
													fill: theme.palette.mode == "light" ? "red" : "white",
													fontSize: "20px",
												}}
											/>
										</Tooltip>
									),
									onClick: () => handleDelete(client),
								},
							]}
							columns={[
								{ title: "Email", key: "email", sortable: true },
								{
									title: "KYC",
									key: "isUserOnboardingComplete",
									sortable: true,
									isPill: true,
								},
								{
									title: "KYB",
									key: "isBusinessOnboardingComplete",
									sortable: true,
									isPill: true,
								},
								{
									title: "Consumer Score",
									key: "consumerCreditScore",
									sortable: true,
									isDecimal: false,
								},
								{
									title: "Consumer Rating",
									key: "consumerCreditCategory",
									sortable: true,
									isPill: true,
								},
								{
									title: "Commercial Score",
									key: "commercialCreditScore",
									sortable: true,
								},
								{
									title: "Commercial Rating",
									key: "commercialCreditCategory",
									sortable: true,
									isPill: true,
								},
							]}
							data={filteredData ? filteredData : []}
							actionColumn={{
								title: "Actions",
							}}
							onRowSelect={(selectedRows) => {
								setSelectedRows(selectedRows);
							}}
							selectable
							selected={selectedRows}
							totalItems={clients?.totalItems || 0}
							handleNextPage={handleFetchNewPage}
							handleSize={handleSize}
						/>
					</>
				</Box>
				{selectedRows.length > 0 && (
					<ClientsFloatingActionMenu
						selectedRows={selectedRows}
						data={selectedRows[selectedRows ? selectedRows.length - 1 : 0]}
						refetch={refetch}
					/>
				)}
			</BaseCard>

			<FundingNeedModal
				open={openAddNewDeal}
				onClose={() => setOpenAddNewDeal(false)}
				selectedApplication={null}
				client={selectedRows[0]}
			/>

			<AddClientModal2
				refetch={() => refetch()}
				open={openAddClientModal}
				onClose={() => setOpenAddClientModal(false)}
			/>

			<ProfileDetailsModal
				open={openViewDetailsModal}
				onClose={() => setOpenViewDetailsModal(false)}
				selectedApplication={selectedRow}
			/>

			<CV4BReportDetailsModal
				open={openViewCV4BReportModal}
				onClose={() => setOpenViewCV4BReportModal(false)}
				selectedRow={selectedRow}
			/>

			<ConfirmationModal
				open={openConfirmationModal}
				onClose={() => setOpenConfirmationModal(false)}
				handleConfirmAction={handleDeleteClient}
				message={confirmationMessage}
				isLoading={isLoading}
			/>
		</Box>
	);
};
export default ManageClientsPage;
