import React from "react";
import {
	ResponsiveContainer,
	PieChart,
	Pie,
	Tooltip,
	Cell,
	Legend,
} from "recharts";
import { Box, CircularProgress, Typography, Alert } from "@mui/material";
import { useLoanBookByCounterPartyValue } from "../../../common/helpers/hooks/useFinanceService";
import BaseCard from "../Cards/BaseCard";
import NoDataFoundCard from "../Cards/NoDataFoundCard";

const LoanBookByCounterpartyValueChart = () => {
	const { data, isLoading, error } = useLoanBookByCounterPartyValue();

	if (isLoading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height={300}
			>
				<CircularProgress color="success" />
			</Box>
		);
	}

	if (error) {
		return (
			<Alert severity="error">
				Failed to load data. Please try again later.
			</Alert>
		);
	}

	if (!data || data?.loans?.length === 0) {
		return <NoDataFoundCard title="Loan Book by Counterparty - Value" />;
	}

	const chartData = data?.loans?.map((item: any) => ({
		percentage: parseFloat(item.percentage.replace("%", "")),
		client: item.clientName,
		total: item.total,
	}));

	const generateColor = (index: number) => {
		const baseR = 97;
		const baseG = 156;
		const baseB = 156;
		const r = (baseR + index * 30) % 255;
		const g = (baseG + index * 40) % 255;
		const b = (baseB + index * 50) % 255;

		const rgbToHex = (r: number, g: number, b: number) => {
			const toHex = (x: number) => x.toString(16).padStart(2, "0");
			return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
		};

		return rgbToHex(r, g, b);
	};

	return (
		<BaseCard sx={{ height: "auto", padding: "20px 10px" }}>
			<Typography variant="body2" gutterBottom>
				Loan Book by Counterparty - Value
			</Typography>
			<ResponsiveContainer width="100%" minHeight={450}>
				<PieChart>
					<Pie
						data={chartData}
						dataKey="percentage"
						nameKey="client"
						cx="50%"
						cy="50%"
						outerRadius={120}
						innerRadius={80}
						label={({ percent, x, y }) => (
							<text
								x={x}
								y={y}
								textAnchor="middle"
								style={{
									fontSize: "10px",

									fill: "#333",
								}}
							>
								{(percent * 100.0).toFixed(2)}%
							</text>
						)}
						labelLine={true}
					>
						{chartData.map((_: any, index: any) => (
							<Cell key={`cell-${index}`} fill={generateColor(index)} />
						))}
					</Pie>
					<Tooltip
						formatter={(value: number, name: string, props: any) => {
							return `${value}%`;
						}}
					/>
					<Legend
						verticalAlign="bottom"
						wrapperStyle={{
							fontSize: "10px",
							padding: "10px",
						}}
						formatter={(value: any) => {
							return <span style={{ fontSize: "12px" }}>{value}</span>;
						}}
					/>
				</PieChart>
			</ResponsiveContainer>
		</BaseCard>
	);
};

export default LoanBookByCounterpartyValueChart;
