import React, { useEffect, useState } from "react";
import {
	Box,
	Typography,
	IconButton,
	Modal,
	Grid,
	useTheme,
	Tooltip,
	Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CopyIcon from "@mui/icons-material/FileCopy";
import CustomButton from "../Elements/CustomButton";
import CustomTextInput from "../Elements/CustomTextInput";
import CustomTabs from "../Elements/CustomTabs";
import CustomTable from "../Tables/CustomTable";
import { useFetchClientDeals } from "../../helpers/hooks/useClientService";
import LoadingIconButton from "../Elements/LoadingIconButton";
import toast from "react-hot-toast";
import CreditReportDetails from "../Forms/CreditReportDetails";
import { ContentCopyOutlined } from "@mui/icons-material";

interface ProfileDetailsModalProps {
	open: boolean;
	onClose: () => void;
	selectedApplication: any;
	tab?: number;
}

const CompleteClientDetailsModal: React.FC<ProfileDetailsModalProps> = ({
	open,
	onClose,
	selectedApplication,
	tab = 0,
}) => {
	const theme = useTheme();
	const [tabIndex, setTabIndex] = useState(tab);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabIndex(newValue);
	};

	const entrepreneurFields = [
		{ label: "First Name", value: selectedApplication?.userProfile?.firstName },
		{ label: "Surname", value: selectedApplication?.userProfile?.surname },
		{ label: "Email", value: selectedApplication?.userProfile?.email },
		{ label: "Mobile No", value: selectedApplication?.userProfile?.mobileNo },
		{ label: "Gender", value: selectedApplication?.userProfile?.gender },
		{ label: "ID Number", value: selectedApplication?.userProfile?.idNumber },
		{ label: "Age", value: selectedApplication?.userProfile?.age },
		{
			label: "Date of Birth",
			value: selectedApplication?.userProfile?.dateOfBirth,
		},
		{
			label: "Residential Address",
			value: selectedApplication?.userProfile?.residentialAddress,
		},
	];

	const businessFields = [
		{
			label: "Business Name",
			value: selectedApplication?.businessProfile?.businessName,
		},
		{
			label: "Company Reg No",
			value: selectedApplication?.businessProfile?.companyRegNo,
		},
		{
			label: "Financial Year End",
			value: selectedApplication?.businessProfile?.financialYearEnd,
		},
		{
			label: "Commercial Status",
			value: selectedApplication?.businessProfile?.commercialStatus,
		},
		{
			label: "Physical Address",
			value: selectedApplication?.businessProfile?.physicalAddress,
		},
		{
			label: "Director Count",
			value: selectedApplication?.businessProfile?.directorCount,
		},
		{
			label: "Legal Entity Type",
			value: selectedApplication?.businessProfile?.legalEntityType,
		},
		{ label: "Tax No", value: selectedApplication?.businessProfile?.taxNo },
	];

	const directorColumns = [
		{ title: "Full Name", key: "fullName", sortable: true },
		{ title: "Phone Number", key: "phoneNumber", sortable: true },
		{ title: "ID Number", key: "idNumber", sortable: true },
		{ title: "Birth Date", key: "birthDate", sortable: true },
		{ title: "Director Status", key: "directorStatus", sortable: true },
		{ title: "Appointment Date", key: "appointmentDate", sortable: true },
	];

	const directorData = selectedApplication?.directors || [];

	const handleCopyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text).then(() => {
			toast.success("Copied to clipboard!");
		});
	};

	useEffect(() => {
		setTabIndex(tab);
	}, [tab]);

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "100vw",
					height: "100vh",
					backgroundColor:
						theme.palette.mode == "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "12px",
					margin: "auto",
					position: "relative",
					top: "0%",
					overflowY: "auto",
				}}
			>
				<Box
					mb={2}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography variant="h6">View Details</Typography>
					<IconButton
						edge="end"
						color="inherit"
						onClick={onClose}
						sx={{ ml: "auto" }}
					>
						<CloseIcon />
					</IconButton>
				</Box>

				<CustomTabs
					tabs={[{ label: "Details" }, { label: "Credit" }]}
					selectedTab={tabIndex}
					onTabChange={handleTabChange}
				/>

				<br />
				{tabIndex === 0 && (
					<Box p={2}>
						<Typography variant="h6">Entrepreneur Details</Typography>
						<form>
							<Grid container spacing={2}>
								{entrepreneurFields.map((field, index) => (
									<Grid item xs={12} md={4} key={index}>
										<Box display="flex" alignItems="center">
											<CustomTextInput
												label={field.label}
												value={field.value}
											/>
											<Tooltip title="Copy to Clipboard">
												<IconButton
													onClick={() =>
														field.value && handleCopyToClipboard(field.value)
													}
													sx={{ ml: 1 }}
													disabled={!field.value}
												>
													<ContentCopyOutlined
														color={field.value ? "success" : "disabled"}
													/>
												</IconButton>
											</Tooltip>
										</Box>
									</Grid>
								))}
							</Grid>
						</form>

						<Divider sx={{ mt: 4 }} />

						<Box mt={2}>
							<Typography variant="h6">Company Details</Typography>
							<form>
								<Grid container spacing={2}>
									{businessFields.map((field, index) => (
										<Grid item xs={12} md={4} key={index}>
											<Box display="flex" alignItems="center">
												<CustomTextInput
													label={field.label}
													value={field.value}
												/>
												<Tooltip title="Copy to Clipboard">
													<IconButton
														onClick={() =>
															field.value && handleCopyToClipboard(field.value)
														}
														sx={{ ml: 1 }}
														disabled={!field.value}
													>
														<ContentCopyOutlined
															color={field.value ? "success" : "disabled"}
														/>
													</IconButton>
												</Tooltip>
											</Box>
										</Grid>
									))}
								</Grid>
							</form>
						</Box>
					</Box>
				)}

				{tabIndex === 1 && (
					<Box p={2}>
						<CreditReportDetails userId={selectedApplication?.userId} />
					</Box>
				)}
			</Box>
		</Modal>
	);
};

export default CompleteClientDetailsModal;
