import { useQuery, useMutation } from 'react-query';
import {
    getAcceptedLoanOffers,
    createOrUpdateLoanOffer,
    createOrUpdateLoanContract,
    getLoanOffersSummary,
    getLendingApplicationsApprovedByOfficer,
    getLoanOffers,
    getLoanContracts,
} from '../../../common/services/retool-service';

export const useFetchAcceptedLoanOffers = () => {
    return useQuery('acceptedLoanOffers', getAcceptedLoanOffers, { staleTime: Infinity });
};


export const useFetchApprovedLoanOffers = (params={}) => {
    return useQuery(['approvedLoanOffers', params], () => getLendingApplicationsApprovedByOfficer(params), { keepPreviousData: true, staleTime: Infinity });
};

export const useFetchLoanOffers = (params = {}) => {
    return useQuery(
        ['loanOffers', params],
        () => getLoanOffers(params),
        {  
            keepPreviousData: true, 
            staleTime: Infinity 
        }
    );
};

export const useFetchLoanContracts = (params = {}) => {
    return useQuery(
        ['loanContracts', params],
        () => getLoanContracts(params), 
        {  
            keepPreviousData: true, 
            staleTime: Infinity 
        }
    );
};

export const useCreateOrUpdateLoanOffer = () => {
    return useMutation((loanOfferRequest) => createOrUpdateLoanOffer(loanOfferRequest));
};

export const useCreateOrUpdateLoanContract = () => {
    return useMutation((loanOfferContractRequest) => createOrUpdateLoanContract(loanOfferContractRequest));
};

export const useFetchLoanOffersSummary = () => {
    return useQuery('loanOffersSummary', getLoanOffersSummary, { staleTime: Infinity });
};
