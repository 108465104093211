import { useQuery, useMutation } from 'react-query';
import {
    getClients,
    getClientDeals,
    softDeleteDeals,
    getClientAndDealSummary,
    updateUserAndBusinessProfile,
    exportTransactionHistories,
    exportMultipleTransactionHistories,
    runKycUsingEmail,
    runKybUsingEmail,
    addUser,
    getClientDealChecklists,
    getPendingApplications,
    getDealChecklist,
    getLendingApplications,
    getBackgroundJobs,
    getUserAssessement,
    getClientBackgroundJobs
} from '../../services/retool-service';
import { useUserId } from './useUserService';

export const useFetchClients = (params = {}) => {
    
    return useQuery(
        ['clients', params],
        () => getClients(params),
        {
            keepPreviousData: true,
        }
    );
};

export const useFetchBackgroundJobs = () => {
    return useQuery('backgroundJobs', getBackgroundJobs);
};

export const useFetchClientBackgroundJobs = (userId: string) => {
    return useQuery(['clientBackgroundJobs',userId], () => getClientBackgroundJobs(userId), {
});
};

export const useFetchClientDeals = (userId:string) => {
   
    return useQuery(['clientDeals', userId], () => getClientDeals(userId), {
        enabled: !!userId
    });
};

export const useFetchClientAssessment = (userId:string) => {
   
    return useQuery(['clientAssessment', userId], () => getUserAssessement(userId), {
        enabled: !!userId
    });
};

export const useFetchDeals = (params = {}) => {
    return useQuery(['deals', params], () => getLendingApplications(params),  {
        keepPreviousData: true,
    });
};

export const useSoftDeleteDeals = () => {
    return useMutation((deleteLendingApplicationsRequest) => softDeleteDeals(deleteLendingApplicationsRequest));
};

export const useFetchClientAndDealSummary = () => {
    return useQuery('clientAndDealSummary', getClientAndDealSummary);
};

export const useUpdateUserAndBusinessProfile = () => {
    return useMutation((knowYourClientBusinessRequest) => updateUserAndBusinessProfile(knowYourClientBusinessRequest));
};

export const useExportTransactionHistories = () => {
    return useMutation((exportTransactionHistoryRequest) => exportTransactionHistories(exportTransactionHistoryRequest));
};

export const useExportMultipleTransactionHistories = () => {
    return useMutation((exportTransactionHistoryRequest) => exportMultipleTransactionHistories(exportTransactionHistoryRequest));
};

export const useRunKycUsingEmail = () => {
    return useMutation((knowYourClientBusinessRequest: any) => runKycUsingEmail(knowYourClientBusinessRequest));
};

export const useRunKybUsingEmail = () => {
    return useMutation((knowYourClientBusinessRequest: any) => runKybUsingEmail(knowYourClientBusinessRequest));
};

export const useAddUser = () => {
    return useMutation((addUserModel: any) => addUser(addUserModel));
};

export const useClientDealChecklists = (userId: string) => {
    return useQuery(['clientDealChecklists', userId], () => getClientDealChecklists(userId), {
        enabled: !!userId,
        select: (data) => {
            const mainData = data?.data?.data || [];
            return { mainData, originalData: data };
        },
    });
};

export const useDealChecklists = (Id: number) => {
    return useQuery(['dealChecklists', Id], () => getDealChecklist(Id), {
        enabled: !!Id,
        select: (data) => {
            const mainData = data?.data?.data || [];
            return { mainData, originalData: data };
        },
    });
};
