import React from "react";
import {
	ResponsiveContainer,
	PieChart,
	Pie,
	Tooltip,
	Cell,
	Legend,
} from "recharts";
import { CircularProgress, Typography, Alert, Box } from "@mui/material";
import { useClientGenderDistribution } from "../../helpers/hooks/useFinanceService";
import BaseCard from "../Cards/BaseCard";
import NoDataFoundCard from "../Cards/NoDataFoundCard";

const ClientGenderDistributionChart = () => {
	const { data, isLoading, error } = useClientGenderDistribution();
	const COLORS = ["#6EAD57", "#3D7F8E"];

	if (isLoading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height={300}
			>
				<CircularProgress color="success" />
			</Box>
		);
	}

	if (error) {
		return (
			<BaseCard sx={{ height: "auto", padding: "20px 10px" }}>
				<Alert severity="error">
					Failed to load data. Please try again later.
				</Alert>
			</BaseCard>
		);
	}

	if (!data || data?.genders?.length === 0) {
		return <NoDataFoundCard title="Client Gender Distribution" />;
	}

	const total = data?.genders?.reduce(
		(acc: number, item: any) => acc + item.total,
		0
	);

	const adjustedGenders = data?.genders?.map((item: any) => ({
		...item,
		percentage: total > 0 ? ((item.total / total) * 100).toFixed(2) : "0.00",
		percent: item.percentage,
	}));

	return (
		<BaseCard sx={{ height: "auto", padding: "20px 10px" }}>
			<Typography variant="body2" gutterBottom>
				Client Gender Distribution
			</Typography>
			<ResponsiveContainer width="100%" height={400}>
				<PieChart margin={{ top: -30, right: 20, bottom: 20, left: 20 }}>
					<Pie
						data={adjustedGenders}
						dataKey="total"
						nameKey="gender"
						cx="50%"
						cy="50%"
						outerRadius={120}
						innerRadius={80}
						label={({ percent, x, y }) => (
							<text
								x={x}
								y={y}
								textAnchor="middle"
								style={{
									fontSize: "10px",
									fill: "#333",
								}}
							>
								{percent}
							</text>
						)}
						labelLine
					>
						{adjustedGenders?.map((_: any, index: any) => (
							<Cell
								key={`cell-${index}`}
								fill={COLORS[index % COLORS.length]}
							/>
						))}
					</Pie>
					<Tooltip
						formatter={(value: number, name: string, props: any) =>
							`${props.payload.percent} | Total: ${props.payload.total}`
						}
					/>
					<Legend
						verticalAlign="bottom"
						wrapperStyle={{
							fontSize: "10px",
							padding: "10px",
						}}
						formatter={(value: any, entry: any) => (
							<span style={{ fontSize: "12px" }}>
								{`${value} - ${entry.payload.total} (${entry.payload.percentage}%)`}
							</span>
						)}
					/>
				</PieChart>
			</ResponsiveContainer>
		</BaseCard>
	);
};

export default ClientGenderDistributionChart;
