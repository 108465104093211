import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import BaseCard from "./BaseCard";
import BarChartWrapper from "../Charts/BarChartWrapper";

interface FundingLadderCardProps {
	title: string;
	data: { productType: string; fundingAmount: number }[] | null;
}

const FundingLadderCard: React.FC<FundingLadderCardProps> = ({
	title,
	data,
}) => {
	const [chartData, setChartData] = useState<{
		labels: string[];
		amounts: number[];
		colors: string[];
	} | null>(null);

	useEffect(() => {
		if (data && data.length > 0) {
			const labels = data.map((item, index) =>
				index === data.length - 1
					? `${item.productType} (Current)`
					: item.productType
			);
			const amounts = data.map((item) => item.fundingAmount);
			const colors = data.map((_, index) =>
				index === data.length - 1 ? "rgba(46, 204, 113, 1)" : "#1976D2"
			); // Green for "Current," Blue for others
			setChartData({ labels, amounts, colors });
		}
	}, [data]);

	const hasData = chartData && chartData.labels.length > 0;

	return (
		<BaseCard sx={{ height: "100%" }}>
			<Box
				display="flex"
				justifyContent="space-between"
				p={2}
				mt={-3}
				height="100%"
			>
				<Box width="100%" height="100%" sx={{ height: "300px" }}>
					<Typography variant="h6" sx={{ mb: 2 }}>
						{title}
					</Typography>
					{hasData ? (
						<BarChartWrapper
							chartData={{
								labels: chartData.labels,
								datasets: [
									{
										label: "Funding Amount",
										data: chartData.amounts,
										backgroundColor: chartData.colors,
										borderWidth: 1,
									},
								],
							}}
							options={{
								responsive: true,
								maintainAspectRatio: false,
								scales: {
									x: {
										title: {
											display: true,
											text: "Product Type",
										},
										grid: {
											display: false, // Remove grid lines
										},
										barThickness: data?.length === 1 ? 50 : 40,
									},
									y: {
										title: {
											display: true,
											text: "Amount",
										},
										beginAtZero: true,
										grid: {
											display: false, // Remove grid lines
										},
									},
								},
								plugins: {
									legend: {
										display: false,
									},
									tooltip: {
										enabled: true,
									},
								},
							}}
						/>
					) : (
						<CircularProgress color="success" size={20} />
					)}
				</Box>
			</Box>
		</BaseCard>
	);
};

export default FundingLadderCard;
