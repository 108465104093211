import React, { useState } from "react";
import {
	Box,
	Modal,
	Typography,
	useTheme,
	CircularProgress,
	IconButton,
} from "@mui/material";
import toast from "react-hot-toast";
import CustomButton from "../Elements/CustomButton";
import CustomTextInput from "../Elements/CustomTextInput";
import { Close } from "@mui/icons-material";
import { useQueryClient } from "react-query";
import { processAccountCreation } from "../../../common/services/retool-service";

interface AddClientModalProps {
	open: boolean;
	onClose: () => void;
	refetch: () => void;
}

const AddClientModal2: React.FC<AddClientModalProps> = ({ open, onClose, refetch }) => {
	const theme = useTheme();
	const [email, setEmail] = useState("");
	const [idNumber, setIdNumber] = useState("");
	const [cipcNo, setCipcNo] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async () => {
		setIsLoading(true);
		const toastId = toast.loading("Account Creation...");

		try {
			const response = await processAccountCreation({
				email,
				idNumber,
				companyRegistrationNo: cipcNo,
			});

			if (response.data.success) {
				toast.success(response.data.message, { id: toastId });
				refetch();
			} else {
				toast.error(response.data.message, { id: toastId });
			}
		} catch (e) {
			toast.error("An error occurred while adding the client.", {
				id: toastId,
			});
		} finally {
			setIsLoading(false);
			onClose();
		}
	};

	const renderFormFields = () => (
		<Box>
			<CustomTextInput
				name="email"
				label="Email"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				sx={{ mb: 2 }}
			/>
			<CustomTextInput
				name="idNumber"
				label="ID Number"
				value={idNumber}
				onChange={(e) => setIdNumber(e.target.value)}
				sx={{ mb: 2 }}
			/>
			<CustomTextInput
				name="cipcNo"
				label="CIPC No"
				value={cipcNo}
				placeholder="xxxx/xxxxxx/xx"
				onChange={(e) => setCipcNo(e.target.value)}
				fullWidth
				sx={{ mb: 2 }}
			/>
		</Box>
	);

	const renderButtons = () => (
		<Box
			display="flex"
			justifyContent="flex-end"
			alignItems="center"
			mt={2}
			gap={2}
		>
			{isLoading ? (
				<CircularProgress size={24} color="success" />
			) : (
				<CustomButton
					text="Submit"
					onClick={handleSubmit}
					variant="contained"
				/>
			)}
		</Box>
	);

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "600px",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "12px",
					margin: "auto",
					overflowY: "auto",
					position: "relative",
					top: "30%",
				}}
			>
				<IconButton
					sx={{
						position: "absolute",
						top: "16px",
						right: "16px",
						color: theme.palette.grey[500],
					}}
					onClick={onClose}
				>
					<Close />
				</IconButton>

				<Typography
					variant="h5"
					mb={4}
					sx={{ color: theme.palette.text.primary }}
				>
					Client Creation
				</Typography>

				{/* Render all the fields here */}
				{renderFormFields()}
				{renderButtons()}
			</Box>
		</Modal>
	);
};

export default AddClientModal2;
