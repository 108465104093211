import { axiosInstance } from "./auth-token";

const URL = `${process.env.REACT_APP_PUBLIC_API_URL}/Finance`;

export const retrieveTransactionSummary = async () => {
    const response = await axiosInstance.get(URL + `/transactions-summary`);
    return response.data.data;
};

const buildDateQueryParams = (startDate, endDate) => {
    const params = {};
    if (startDate) params["startDate"] = startDate;
    if (endDate) params["endDate"] = endDate;
    return params;
};

export const getMonthlyCapitalAdvances = async (startDate, endDate) => {
    const params = buildDateQueryParams(startDate, endDate);
    const response = await axiosInstance.get(URL + `/monthly-capital-advances`, { params });
    return response.data.data;
};

export const getRevenuesCollected = async (startDate, endDate) => {
    const params = buildDateQueryParams(startDate, endDate);
    const response = await axiosInstance.get(URL + `/revenues-collected`, { params });
    return response.data.data;
};

export const getTotalMonthlyLoans = async (startDate, endDate) => {
    const params = buildDateQueryParams(startDate, endDate);
    const response = await axiosInstance.get(URL + `/total-monthly-loans`, { params });
    return response.data.data;
};

export const getTotalMonthlyLoansPerClient = async (startDate, endDate) => {
    const params = buildDateQueryParams(startDate, endDate);
    const response = await axiosInstance.get(URL + `/loan-book-counter-party-loans`, { params });
    return response.data.data;
};

export const getLoanBookByCounterPartyValue = async (startDate, endDate) => {
    const params = buildDateQueryParams(startDate, endDate);
    const response = await axiosInstance.get(URL + `/loan-book-counter-party-value`, { params });
    return response.data.data;
};

export const getClientSectorDistribution = async (startDate, endDate) => {
    const params = buildDateQueryParams(startDate, endDate);
    const response = await axiosInstance.get(URL + `/client-sector-distribution`, { params });
    return response.data.data;
};

export const getClientGenderDistribution = async (startDate, endDate) => {
    const params = buildDateQueryParams(startDate, endDate);
    const response = await axiosInstance.get(URL + `/client-gender-distribution`, { params });
    return response.data.data;
};

export const getCumulativeClientsPerMonth = async (startDate, endDate) => {
    const params = buildDateQueryParams(startDate, endDate);
    const response = await axiosInstance.get(URL + `/cumilative-clients-per-month`, { params });
    return response.data.data;
};

export const getCumulativeCapitalAdvances = async (startDate, endDate) => {
    const params = buildDateQueryParams(startDate, endDate);
    const response = await axiosInstance.get(URL + `/cumilative-capital-advances`, { params });
    return response.data.data;
};

export const disburseDeal = async (payload) => {
    const response = await axiosInstance.post(URL + `/update-disbursement-status`, payload);
    return response.data;
};