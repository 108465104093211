import React, { useState } from "react";
import { Box, Tooltip, useTheme } from "@mui/material";
import CustomSearchBar from "../Elements/CustomSearchBar";
import CustomTable from "./CustomTable";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import UpdateOfferStatusModal from "../Modals/UpdateOfferStatus";
import CardWithIcon from "../Elements/CardWithIcon";
import { AddCircle } from "@mui/icons-material";

const OffersTable = ({
	offersSearchTerm,
	handleOffersSearchChange,
	isLoadingOffers,
	filteredOffersData,
	setSelectedRow,
	setOpenCreateOfferModal,
	handleDelete,
	setSelectedRows,
	selectedRows,
	totalItems,
	page,
	handleFetchNewPage,
	isRefetching,
	handleSize,
	handleSearch,
}) => {
	const theme = useTheme();
	const [openUpdateOfferStatusModal, setOpenUpdateOfferStatusModal] =
		useState(false);

	return (
		<>
			<Box display="flex" alignItems="center" gap={2} my={2}>
				<Box sx={{ flexGrow: 1 }}>
					<CustomSearchBar
						searchTerm={offersSearchTerm}
						onSearchChange={handleOffersSearchChange}
						handleSearch={handleSearch}
					/>
				</Box>

				<Box sx={{ marginLeft: "auto" }}>
					{selectedRows.length == 1 && (
						<Box sx={{ marginLeft: "auto" }}>
							<CardWithIcon
								icon={
									<AddCircle
										style={{
											color:
												theme.palette.mode == "light"
													? "rgba(73, 69, 79, 1)"
													: "white",
											marginRight: "5px",
											fontSize: "18px",
										}}
									/>
								}
								text="Update Status"
								onClick={() => {
									setOpenUpdateOfferStatusModal(true);
								}}
							/>
						</Box>
					)}
				</Box>

				<UpdateOfferStatusModal
					open={openUpdateOfferStatusModal}
					onClose={() => setOpenUpdateOfferStatusModal(false)}
					offer={selectedRows[0]}
				/>
			</Box>
			<CustomTable
				isLoading={isLoadingOffers || isRefetching}
				icons={(offer) => [
					{
						icon: (
							<Tooltip title="View/Update Loan Offer" arrow>
								<Edit
									sx={{
										fill: theme.palette.mode === "light" ? "green" : "white",
										fontSize: "20px",
									}}
								/>
							</Tooltip>
						),
						onClick: () => {
							setSelectedRow(offer);
							setOpenCreateOfferModal(true);
						},
					},
					{
						icon: (
							<Tooltip title="Delete Loan Offer" arrow>
								<Delete
									sx={{
										fill: theme.palette.mode === "light" ? "red" : "white",
										fontSize: "20px",
									}}
								/>
							</Tooltip>
						),
						onClick: () => handleDelete(offer),
					},
				]}
				columns={[
					{ title: "Email", key: "email", sortable: true },
					{
						title: "Product",
						key: "productType",
						sortable: true,
						isPill: true,
					},
					{ title: "Amount", key: "approvedFundingAmount", sortable: true },
					{ title: "Duration (days)", key: "approvedDuration", sortable: true },
					{ title: "Status", key: "status", sortable: true, isPill: true },
					{ title: "Created", key: "createdTimestamp", sortable: true },
				]}
				data={filteredOffersData}
				actionColumn={{ title: "Actions" }}
				selectable
				onRowSelect={(rows) => {
					setSelectedRows(rows);
				}}
				selected={selectedRows}
				totalItems={totalItems || 0}
				handleNextPage={handleFetchNewPage}
				page={page}
				handleSize={handleSize}
			/>
		</>
	);
};

export default OffersTable;
