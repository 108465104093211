import axios from "axios";
import { getCookie, setCookie, removeCookie } from 'typescript-cookie';
import toast from "react-hot-toast";

export const axiosInstance = axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_API_URL}`,
});

let unauthorizedErrorShown = false;
let forbiddenErrorShown = false;

axiosInstance.interceptors.request.use(
    
    async (config) => {
        
        const publicEndpoints = [
            '/save-new-contact',
            '/run-bank-statement-ocr'
        ];

        const isPublicEndpoint = publicEndpoints.some(endpoint => config.url.includes(endpoint));

        if (isPublicEndpoint) {
            return config;
        }

        const token = getCookie("accessToken");

        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            return config;
        }

        try {

            await refreshAccessToken(config);
            return config;

        } catch (error) {
            return Promise.reject(error);
        }

    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {

            const status = error.response.status;
            const redirectUrl = error.response.headers['Redirect-Url'];

            if (status === 401 && !unauthorizedErrorShown) {
                unauthorizedErrorShown = true;
                handleUnauthorizedError(redirectUrl);
            } else if (status === 403 && !forbiddenErrorShown) {
                forbiddenErrorShown = true;
                handleForbiddenError();
            }
        }
        return Promise.reject(error);
    }
);


const refreshAccessToken = async (config) => {
    const refreshToken = getCookie("refreshToken");
    const accessToken = getCookie("accessToken");
    
    if (!accessToken && !refreshToken) {
        redirectToLogin();
        return;
    }

    try {
        const response = await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/Auth/refresh-token`, {
            refreshToken,
            accessToken
        });
        const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data;
        setCookie("accessToken", newAccessToken);
        setCookie("refreshToken", newRefreshToken);
        config.headers["Authorization"] = `Bearer ${newAccessToken}`;

    } catch (e) {
        redirectToLogin();
    }

};

const handleUnauthorizedError = (url) => {
    toast.error("Session expired. Please log in again.");
    removeCookies();
    redirectToLogin(url);
};

const redirectToLogin = (url) => {
    if (!url) {
        const redirectUrl = '/authentication/login?returnUrl=dashboard';
        window.location.href = redirectUrl;
    } else {
        window.location.href = url;
    }
};


const handleForbiddenError = () => {
    toast.error("Access denied. You do not have permissions to access this feature.");
};

const removeCookies = () => {
    removeCookie("accessToken");
    removeCookie("refreshToken");
    localStorage.removeItem('userId');
};

export const isAuthenticated = () => {
    const refreshToken = getCookie("refreshToken");
    const accessToken = getCookie("accessToken");
    return accessToken && refreshToken;
}
