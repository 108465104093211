import React, { useState } from "react";
import {
	Modal,
	Box,
	Typography,
	CircularProgress,
	useTheme,
	FormControlLabel,
	Checkbox,
} from "@mui/material";

interface ConfirmationModalProps {
	open: boolean;
	onClose: () => void;
	onConfirm: () => void;
	isLoading: boolean;
	message: string;
	setIsApproved: Function;
	isApproved: boolean;
}

const SendConfirmationModal: React.FC<ConfirmationModalProps> = ({
	open,
	onClose,
	onConfirm,
	isLoading,
	message,
	setIsApproved,
	isApproved,
}) => {
	const theme = useTheme();

	const handleCheckboxChange = () => {
		setIsApproved(!isApproved);
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					maxWidth: "600px",
					height: "auto",
					maxHeight: "100vh",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "12px",
					margin: "auto",
					overflowY: "auto",
				}}
			>
				<Typography variant="h6" component="h2" gutterBottom>
					Confirm Action
				</Typography>

				<Typography variant="body1" gutterBottom>
					{message}
				</Typography>

				<FormControlLabel
					control={
						<Checkbox
							color="success"
							checked={isApproved}
							onChange={handleCheckboxChange}
						/>
					}
					label="I have reviewed and approve this action"
				/>

				<Box
					p={2}
					sx={{
						display: "flex",
						gap: 2,
						justifyContent: "flex-end",
						alignItems: "center",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 500,
							fontSize: "14px",
							lineHeight: "20px",
							letterSpacing: "0.1px",
							color: "rgba(61, 127, 142, 1)",
							cursor: "pointer",
						}}
						onClick={onClose}
					>
						Cancel
					</Typography>

					{isLoading ? (
						<CircularProgress size="1rem" color="success" />
					) : (
						<Typography
							onClick={onConfirm}
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								lineHeight: "20px",
								letterSpacing: "0.1px",
								color: "rgba(61, 127, 142, 1)",
								cursor: "pointer",
							}}
						>
							Confirm
						</Typography>
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default SendConfirmationModal;
