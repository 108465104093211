import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { login as loginAction } from "../../features/auth/authSlice"; 
import { RootState, AppDispatch } from "../../../app/store";
import { useFormik } from "formik";
import { SerializedError } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

interface LoginFormValues {
  email: string;
  password: string;
  submit?: null;
}

const useLoginForm = (initialEmail?: string) => {

  const dispatch = useDispatch<AppDispatch>();
  const authError = useSelector((state: RootState) => state.auth.error);
  const navigate = useNavigate();

  const formik = useFormik<LoginFormValues>({
    initialValues: {
      email: initialEmail || "",
      password: "",
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      password: Yup.string()
        .max(255)
        .required("Password is required"),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
      
       await dispatch(loginAction({ email: values.email, password: values.password })).unwrap();
        navigate("/home");
      } catch (error) {

        const errorMessage =
         
          ((error as string) ?? "An unexpected error occurred");

          
          
        setErrors({ submit: errorMessage });

      } finally {
        setSubmitting(false);
      }
    },
  });

  return formik;
};

export default useLoginForm;
