import React from "react";
import { ToggleSwitch } from "../Elements/CustomSwitch";
import {
	CheckCircle,
	Error,
	ArrowBack,
	ArrowForward,
	Flag,
	ContentCopyOutlined,
} from "@mui/icons-material";
import { Checkbox, IconButton } from "@mui/material";
import { toast } from "react-hot-toast";
import { TableCell, TableRowStyled } from "../Styled/TableComponents";

const CustomTableBody = ({
	actionColumn,
	icons,
	paginatedData,
	columns,
	renderCellValue,
	handleRowSelection,
	selectedRows,
	selectable,
	copyToClipboard,
}) => {
	const handleCopy = (value) => {
		if (value) {
			navigator.clipboard.writeText(value);
			toast.success("Copied to clipboard!");
		}
	};

	return (
		<tbody>
			{paginatedData.length === 0 ? (
				<TableRowStyled>
					<TableCell
						colSpan={
							columns.length + (selectable ? 1 : 0) + (actionColumn ? 1 : 0)
						}
						style={{ textAlign: "center" }}
					>
						No data found.
					</TableCell>
				</TableRowStyled>
			) : (
				paginatedData.map((row, rowIndex) => (
					<TableRowStyled
						key={rowIndex}
						onClick={() => handleRowSelection(row)}
					>
						{selectable && (
							<th key={rowIndex}>
								<Checkbox
									color="success"
									checked={selectedRows.includes(row)}
									onChange={() => handleRowSelection(row)}
								/>
							</th>
						)}
						{columns.map((column) => (
							<TableCell key={column.key}>
								{renderCellValue(row, column)}
								{copyToClipboard && (
									<IconButton
										onClick={(e) => {
											e.stopPropagation();
											handleCopy(row[column.key]);
										}}
										disabled={!row[column.key]}
										sx={{ ml: 1 }}
									>
										<ContentCopyOutlined
											color={row[column.key] ? "success" : "disabled"}
										/>
									</IconButton>
								)}
							</TableCell>
						))}
						{actionColumn && (
							<TableCell>
								{icons &&
									icons(row).map((icon, index) => (
										<IconButton key={index} onClick={() => icon.onClick(row)}>
											{icon.icon}
										</IconButton>
									))}
							</TableCell>
						)}
					</TableRowStyled>
				))
			)}
		</tbody>
	);
};

export default CustomTableBody;
