import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CustomTable from "../../common/components/Tables/CustomTable";
import CustomSearchBar from "../../common/components/Elements/CustomSearchBar";
import { CircularProgress, Tooltip, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { getExcelCreditReportExtracts } from "../../common/services/retool-service";
import { Download, Visibility } from "@mui/icons-material";
import { useQuery } from "react-query";
import CustomButton from "../../common/components/Elements/CustomButton";
import BaseCard from "../../common/components/Cards/BaseCard";
import GreetingMessage from "../../common/components/Headings/GreetingMessage";
import AccordionModal from "../../common/components/Modals/AccordionModal";
import CustomTabs from "../../common/components/Elements/CustomTabs";

const ManageCreditReports: React.FC = () => {
	const [open, setOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [filteredData, setFilteredData] = useState<any[]>([]);
	const theme = useTheme();
	const navigate = useNavigate();
	const [selectedRow, setSelectedRow] = useState(null);
	const [tabIndex, setTabIndex] = useState(0);

	const [page, setPage] = useState(0);
	const [size, setSize] = useState(10);
	const [search, setSearch] = useState("");

	const tabs = [
		{ label: "All" },
		{ label: "Consumer" },
		{ label: "Commercial" },
	];

	const { data, isLoading, refetch, isRefetching } = useQuery(
		["creditReportExtracts", { page }],
		() => getExcelCreditReportExtracts({ page, size, searchTerm: search }),
		{ staleTime: Infinity, keepPreviousData: true }
	);

	const handleFetchNewPage = async (newPage: number) => {
		setPage(newPage);
		setTabIndex(0);
	};

	const handleSize = async (newSize: number) => {
		setSize(newSize);
	};
	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const handleSubmitSearch = () => {
		setSearch(searchTerm);
	};

	useEffect(() => {
		if (data?.data?.data) {
			const searchTermLower = searchTerm.toLowerCase();
			const newFilteredData = data.data.data.filter((item: any) => {
				return (
					(item?.clientName?.toLowerCase().includes(searchTermLower) ??
						false) ||
					(item?.cipcRegNo?.toLowerCase().includes(searchTermLower) ?? false) ||
					(item?.idNumber?.toLowerCase().includes(searchTermLower) ?? false)
				);
			});
			setFilteredData(newFilteredData);
		}
	}, [searchTerm, data]);

	const handleDownload = (link: string) => {
		window.open(link, "_blank");
	};

	const location = useLocation();

	useEffect(() => {
		refetch();
	}, [location.pathname]);

	useEffect(() => {
		if (filteredData) {
			const reports = data?.data?.data;
			const filteredByTab =
				tabIndex === 0
					? reports
					: reports.filter((item: any) =>
							tabIndex === 1
								? item.type === "Consumer"
								: item.type === "Commercial"
						);
			setFilteredData(filteredByTab);
		}
	}, [tabIndex]);

	return (
		<Box
			sx={{
				py: 4,
				px: 3,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				textAlign: "center",
				width: "100%",
				gap: 2,
			}}
		>
			<BaseCard sx={{ height: "auto" }}>
				<Box px={2} py={4}>
					<GreetingMessage title="Manage Credit Reports" />
					<Box
						width="100%"
						my={2}
						gap={2}
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Box sx={{ flexGrow: 1 }}>
							<CustomSearchBar
								searchTerm={searchTerm}
								onSearchChange={handleSearchChange}
								handleSearch={handleSubmitSearch}
							/>
						</Box>
						<CustomButton
							text={
								isRefetching ? (
									<CircularProgress size="20px" color="success" />
								) : (
									"Refresh"
								)
							}
							variant="outlined"
							color="secondary"
							onClick={() => refetch()}
						/>
					</Box>
					<Box
						width="100%"
						my={2}
						gap={2}
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Box sx={{ flexGrow: 1 }}>
							<CustomTabs
								tabs={tabs}
								selectedTab={tabIndex}
								onTabChange={(event, newValue) => {
									setTabIndex(newValue);
									setPage(0);
								}}
							/>
						</Box>
					</Box>
					<CustomTable
						isLoading={isLoading || isRefetching}
						columns={[
							{ title: "Client Name", key: "clientName", sortable: true },
							{ title: "CIPC Reg No", key: "cipcRegNo", sortable: true },
							{ title: "ID Number", key: "idNumber", sortable: true },
							{ title: "Type", key: "type", sortable: true, isPill: true },
							{ title: "Created", key: "created", sortable: true },
						]}
						data={filteredData}
						icons={(row) => [
							{
								icon: (
									<Tooltip title="Download Report" arrow>
										<Download
											sx={{
												fill: theme.palette.mode == "light" ? "green" : "white",
												fontSize: "20px",
											}}
										/>
									</Tooltip>
								),
								onClick: () => handleDownload(row.downloadLink),
							},
							{
								icon: (
									<Tooltip title="View Report" arrow>
										<Visibility
											sx={{
												fill: theme.palette.mode == "light" ? "green" : "white",
												fontSize: "20px",
											}}
										/>
									</Tooltip>
								),
								onClick: () => {
									setSelectedRow(row);
									setOpen(true);
								},
							},
						]}
						actionColumn={{
							title: "Actions",
						}}
						totalItems={
							tabIndex == 0 ? data?.data?.totalItems : filteredData.length
						}
						page={page}
						handleNextPage={handleFetchNewPage}
						handleSize={handleSize}
					/>
				</Box>
			</BaseCard>
			<AccordionModal
				open={open}
				handleClose={() => setOpen(false)}
				selectedRow={selectedRow}
			/>
		</Box>
	);
};

export default ManageCreditReports;
