import React, { useState, useEffect } from "react";
import {
	Box,
	Typography,
	Grid,
	IconButton,
	useTheme,
	Tooltip,
	Divider,
} from "@mui/material";
import { useQuery } from "react-query";
import CustomTable from "../Tables/CustomTable";
import CustomTextInput from "../Elements/CustomTextInput";
import CopyIcon from "@mui/icons-material/FileCopy";
import { toast } from "react-hot-toast";
import {
	getCommercialCreditReport,
	getConsumerCreditReport,
} from "../../../common/services/retool-service";
import { ContentCopyOutlined } from "@mui/icons-material";
import StatusChip from "../Elements/StatusChip";
import { useNavigate } from "react-router-dom";
import LoadingIconButton from "../Elements/LoadingIconButton";
import GreetingMessage from "../Headings/GreetingMessage";

interface CreditReportDetailsProps {
	userId: string;
}

const CreditReportDetails: React.FC<CreditReportDetailsProps> = ({
	userId,
}) => {
	const theme = useTheme();
	const navigate = useNavigate();

	const {
		data: consumerData,
		isLoading: consumerLoading,
		isRefetching: consumerRefetching,
		refetch: refetchConsumerReport,
	} = useQuery(
		["consumerCreditReports", userId],
		() => (userId ? getConsumerCreditReport(userId) : Promise.resolve(null)),
		{ staleTime: Infinity, enabled: !!userId }
	);

	const {
		data: commercialData,
		isLoading: commercialLoading,
		isRefetching: commercialRefetching,
		refetch: refetchCommercialReport,
	} = useQuery(
		["commercialCreditReports", userId],
		() => (userId ? getCommercialCreditReport(userId) : Promise.resolve(null)),
		{ staleTime: Infinity, enabled: !!userId }
	);

	const handleCopyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text).then(() => {
			toast.success("Copied to clipboard!");
		});
	};
	const consumerCreditScore =
		consumerData?.data?.data?.consumerScoring?.finalScore || "-";
	const consumerCreditCategory =
		consumerData?.data?.data?.consumerScoring?.riskCategory || "N/A";
	const commercialCreditScore =
		commercialData?.data?.data?.commercialScoring?.finalScore || "-";
	const commercialCreditCategory =
		commercialData?.data?.data?.commercialScoring?.band || "N/A";
	const commercialCreditReport =
		commercialData?.data?.data?.commercialBusinessInformation || {};
	const applyingDirectorCreditReport =
		consumerData?.data?.data?.consumerDetail || {};
	const employmentHistory =
		consumerData?.data?.data?.consumerEmploymentHistory || [];
	const properties =
		consumerData?.data?.data?.consumerPropertyInformationSummary || [];
	const consumerAccounts =
		consumerData?.data?.data?.consumerNLRAccountStatus || [];

	const directorData =
		commercialData?.data?.data?.commercialPrincipalInformation || [];
	return (
		<Box
			sx={{
				width: "100%",
				height: "100vh",
				backgroundColor:
					theme.palette.mode === "dark"
						? theme.palette.background.default
						: theme.palette.background.paper,

				borderRadius: "12px",
				margin: "auto",
			}}
		>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				my={2}
			>
				<GreetingMessage title="Credit Report Details" />
				<LoadingIconButton
					onClick={() => {
						refetchCommercialReport();
						refetchConsumerReport();
					}}
					isLoading={consumerRefetching || commercialRefetching}
				/>
			</Box>

			<Grid container spacing={2} justifyContent="center">
				<Grid
					item
					xs={12}
					sm={6}
					sx={{ cursor: "pointer" }}
					onClick={() => navigate("/manage-reports")}
				>
					<Box
						sx={{
							border: "1px solid",
							padding: "16px",
							borderRadius: "8px",
							textAlign: "center",
						}}
					>
						<Typography variant="h6">Consumer Credit Score</Typography>
						<Box sx={{ mt: 1 }}>
							<Typography variant="h6">
								{isNaN(Number(consumerCreditScore))
									? 0
									: Number(consumerCreditScore)}
								<Tooltip title="Copy to Clipboard">
									<IconButton
										onClick={(e) => {
											e.stopPropagation();
											handleCopyToClipboard(consumerCreditScore);
										}}
										sx={{ ml: 1 }}
										disabled={!consumerCreditScore}
									>
										<ContentCopyOutlined
											color={consumerCreditScore ? "success" : "disabled"}
										/>
									</IconButton>
								</Tooltip>
							</Typography>
							{consumerCreditCategory && (
								<StatusChip value={consumerCreditCategory} />
							)}
						</Box>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					sx={{ cursor: "pointer" }}
					onClick={() => navigate("/manage-reports")}
				>
					<Box
						sx={{
							border: "1px solid",
							padding: "16px",
							borderRadius: "8px",
							textAlign: "center",
						}}
					>
						<Typography variant="h6">Commercial Credit Score</Typography>
						<Box sx={{ mt: 1 }}>
							<Typography variant="h6">
								{isNaN(Number(commercialCreditScore))
									? 0
									: Number(commercialCreditScore)}
								<Tooltip title="Copy to Clipboard">
									<IconButton
										onClick={(e) => {
											e.stopPropagation();
											handleCopyToClipboard(commercialCreditScore);
										}}
										sx={{ ml: 1 }}
										disabled={!commercialCreditScore}
									>
										<ContentCopyOutlined
											color={commercialCreditScore ? "success" : "disabled"}
										/>
									</IconButton>
								</Tooltip>
							</Typography>
							{commercialCreditCategory && (
								<StatusChip value={commercialCreditCategory} />
							)}
						</Box>
					</Box>
				</Grid>
			</Grid>

			<Divider sx={{ mt: 4 }} />

			<Box mt={4}>
				<Typography variant="h6">Commercial Credit Report</Typography>
				<Grid container spacing={2}>
					{[
						{
							label: "Company Name",
							value: commercialCreditReport.commercialName,
						},
						{
							label: "Company Registration No",
							value: commercialCreditReport.registrationNo,
						},
						{
							label: "Business Start Date",
							value: commercialCreditReport.businessStartDate,
						},
						{
							label: "Physical Address",
							value: commercialCreditReport.physicalAddress,
						},
						{
							label: "Credit Category",
							value: commercialCreditCategory,
						},
						{
							label: "Commercial Status",
							value: commercialCreditReport.commercialStatus,
						},
						{ label: "Tax Number", value: commercialCreditReport.taxNumber },
						{
							label: "Financial Year End",
							value: commercialCreditReport.financialYearEnd,
						},
					].map((field, index) => (
						<Grid item xs={12} md={6} key={index}>
							<Box display="flex" alignItems="center">
								<CustomTextInput label={field.label} value={field.value} />
								<Tooltip title="Copy to Clipboard">
									<IconButton
										onClick={() =>
											field.value && handleCopyToClipboard(field.value)
										}
										sx={{ ml: 1 }}
										disabled={!field.value}
									>
										<ContentCopyOutlined
											color={field.value ? "success" : "disabled"}
										/>
									</IconButton>
								</Tooltip>
							</Box>
						</Grid>
					))}
				</Grid>
			</Box>
			<Divider sx={{ mt: 4 }} />
			<Box mt={4}>
				<Typography variant="h6">Director Details</Typography>
				<CustomTable
					isLoading={commercialLoading}
					columns={[
						{ title: "Full Name", key: "fullname" },
						{ title: "Phone Number", key: "cellularNo" },
						{ title: "ID Number", key: "idNo" },
						{ title: "Birth Date", key: "birthDate" },
						{ title: "Director Status", key: "directorStatusCode" },
						{ title: "Appointment Date", key: "appointmentDate" },
					]}
					data={directorData}
					copyToClipboard={true}
				/>
			</Box>

			<Box mt={4}>
				<Typography variant="h6">Consumer Directorship</Typography>
				<CustomTable
					copyToClipboard={true}
					isLoading={consumerLoading}
					columns={[
						{ title: "Name", key: "commercialName" },
						{ title: "Status", key: "commercialStatus", isPill: true },
						{ title: "Registration No", key: "registrationNo" },
						{ title: "Director Status", key: "directorStatus" },
						{ title: "Appointment Date", key: "appointmentDate" },
					]}
					data={employmentHistory}
				/>
			</Box>

			<Box mt={4}>
				<Typography variant="h6">Applying Director Credit Report</Typography>
				<Grid container spacing={2}>
					{[
						{
							label: "First Name",
							value: applyingDirectorCreditReport.firstName,
						},
						{ label: "Surname", value: applyingDirectorCreditReport.surname },
						{
							label: "Id Number",
							value: applyingDirectorCreditReport.idNo,
						},
						{ label: "Gender", value: applyingDirectorCreditReport.gender },
						{
							label: "Credit Category",
							value: consumerCreditCategory,
						},
						{
							label: "Email",
							value: applyingDirectorCreditReport.emailAddress,
						},
						{
							label: "Phone Number",
							value: applyingDirectorCreditReport.cellularNo,
						},
						{
							label: "Work Telephone No",
							value: applyingDirectorCreditReport.workTelephoneNo,
						},
						{
							label: "Residential Address",
							value: applyingDirectorCreditReport.residentialAddress,
						},
					].map((field, index) => (
						<Grid item xs={12} md={6} key={index}>
							<Box display="flex" alignItems="center">
								<CustomTextInput label={field.label} value={field.value} />
								<Tooltip title="Copy to Clipboard">
									<IconButton
										onClick={() =>
											field.value && handleCopyToClipboard(field.value)
										}
										sx={{ ml: 1 }}
										disabled={!field.value}
									>
										<ContentCopyOutlined
											color={field.value ? "success" : "disabled"}
										/>
									</IconButton>
								</Tooltip>
							</Box>
						</Grid>
					))}
				</Grid>
			</Box>
			<Divider sx={{ mt: 4 }} />
			<Box mt={4}>
				<Typography variant="h6">Employment History</Typography>
				<CustomTable
					copyToClipboard={true}
					isLoading={consumerLoading}
					columns={[
						{ title: "Employer Name", key: "employerDetail" },
						{ title: "Designation", key: "designation" },
						{ title: "Last Updated Date", key: "lastUpdatedDate" },
						{ title: "First Reported Date", key: "firstReportedDate" },
					]}
					data={employmentHistory}
				/>
			</Box>
			<Divider sx={{ mt: 4 }} />
			<Box mt={4}>
				<Typography variant="h6">Properties</Typography>
				<CustomTable
					isLoading={consumerLoading}
					columns={[
						{ title: "Total Property", key: "totalProperty" },
						{ title: "Purchase Price", key: "purchasePrice" },
					]}
					data={properties}
					copyToClipboard={true}
				/>
			</Box>
			<Divider sx={{ mt: 4 }} />
			<Box mt={4}>
				<Typography variant="h6">Consumer Accounts</Typography>
				<CustomTable
					isLoading={consumerLoading}
					columns={[
						{ title: "Account Opened Date", key: "accountOpenedDate" },
						{ title: "Subscriber Name", key: "subscriberName" },
						{ title: "Credit Limit Amount", key: "creditLimitAmt" },
						{ title: "Current Balance Amount", key: "currentBalanceAmt" },
						{
							title: "Monthly Installment Amount",
							key: "monthlyInstalmentAmt",
						},
						{ title: "Arrears Amount", key: "arrearsAmt" },
						{ title: "Last Payment Date", key: "lastPaymentDate" },
						{ title: "Status Code Desc", key: "statusCodeDesc" },
					]}
					data={consumerAccounts}
					copyToClipboard={true}
				/>
			</Box>
		</Box>
	);
};

export default CreditReportDetails;
