import React from "react";
import {
	ResponsiveContainer,
	LineChart,
	Line,
	XAxis,
	YAxis,
	Tooltip,
	CartesianGrid,
} from "recharts";
import { Box, CircularProgress, Typography, Alert } from "@mui/material";
import { useCumulativeCapitalAdvances } from "../../helpers/hooks/useFinanceService";
import BaseCard from "../Cards/BaseCard";
import NoDataFoundCard from "../Cards/NoDataFoundCard";
import NoDataFound from "../Elements/NoDataFound";

const CumulativeCapitalAdvancesChart = () => {
	const { data, isLoading, error } = useCumulativeCapitalAdvances();

	if (isLoading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height={300}
			>
				<CircularProgress />
			</Box>
		);
	}

	if (error) {
		return (
			<Alert severity="error">
				Failed to load data. Please try again later.
			</Alert>
		);
	}

	if (!data || data?.monthlyCapitalAdvance?.length === 0) {
		return <NoDataFoundCard title="Cumulative Capital" />;
	}

	return (
		<BaseCard sx={{ height: "auto", padding: "20px 10px" }}>
			<Typography variant="body2" fontWeight={500} gutterBottom>
				Cumulative Capital Advances
			</Typography>
			<ResponsiveContainer width="100%" height={300}>
				<LineChart
					data={data?.monthlyCapitalAdvance}
					margin={{ top: 20, right: 20, left: 20, bottom: 10 }}
				>
					<XAxis dataKey="month" fontSize={14} />
					<YAxis
						fontSize={12}
						tickFormatter={(value: number) =>
							value >= 1_000_000
								? `R ${(value / 1_000_000).toFixed(1)}M`
								: value >= 1_000
									? `R ${(value / 1_000).toFixed(1)}K`
									: `R ${value}`
						}
						label={{
							value: "Amount (R)",
							angle: -90,
							position: "insideLeft",
							fontSize: 14,
							dx: -15,
						}}
					/>
					<Tooltip
						formatter={(value: number) => `R ${value.toLocaleString()}`}
					/>
					<Line
						type="monotone"
						dataKey="amount"
						stroke="#619C9C"
						strokeWidth={2}
						dot={true}
					/>
				</LineChart>
			</ResponsiveContainer>
			<Typography variant="body2" fontWeight={500} gutterBottom>
				Date (Month - Year)
			</Typography>
		</BaseCard>
	);
};

export default CumulativeCapitalAdvancesChart;
