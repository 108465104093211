import React, { useState } from "react";
import Box from "@mui/material/Box";
import GreetingMessage from "../../common/components/Headings/GreetingMessage";
import ResponsiveGrid from "../../common/components/Grids/ResponsiveGrid";
import { useComponentRegistryContext } from "../../contexts/componentRegistryContext";
import { AddCircle } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import OnboardSMEModal from "../../common/components/Modals/OnboardingSMEModal";
import CardWithIcon from "../../common/components/Elements/CardWithIcon";
import { useCurrentUser } from "../../common/helpers/hooks/useUserService";
import { CircularProgress } from "@mui/material";
import LoanBookByCounterpartyValueChart from "../../common/components/Charts/LoanBookByCounterpartyValueChart";
import ClientSectorDistributionChart from "../../common/components/Charts/ClientSectorDistributionChart";
import ClientGenderDistributionChart from "../../common/components/Charts/ClientGenderDistributionChart";
import MonthlyCapitalAdvancesChart from "../../common/components/Charts/MonthlyCapitalAdvancesChart";
import CumulativeCapitalAdvancesChart from "../../common/components/Charts/CumulativeCapitalAdvancesChart";
import RevenuesCollectedChart from "../../common/components/Charts/RevenuesCollectedChart";
import TotalMonthlyLoansChart from "../../common/components/Charts/TotalMonthlyLoansChart";
import LoanBookByCounterpartyLoansChart from "../../common/components/Charts/LoanBookByCounterpartyLoansChart";
import CumulativeClientsPerMonthChart from "../../common/components/Charts/CumulativeClientsPerMonthChart";
import CreateDealModal from "../../common/components/Modals/CreateDealModal";
import AddClientModal2 from "../../common/components/Modals/AddClientModal2";
import CustomFab from "../../common/components/Elements/CustomFab";
import DateFilter from "../../common/components/Elements/DateFilter";

const HomePage: React.FC = () => {
	const theme = useTheme();
	const [selectedRows, setSelectedRows] = useState<any[]>([]);
	const [openAddNewDeal, setOpenAddNewDeal] = useState(false);
	const user = useCurrentUser();
	return (
		<Box
			sx={{
				py: 4,
				px: 3,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				textAlign: "center",
				width: "100%",
				gap: 2,
			}}
		>
			<Box
				width="100%"

				sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
			>
			<Box sx={{ flexGrow: 1 }}>
				<GreetingMessage
					title={`Hi ${user?.name?.trim() ? user.name.toLowerCase() : user?.email?.toLowerCase()}`}
					subtitle=""
				/>
				</Box>

				<Box sx={{ marginLeft: "auto" }}>
					<CardWithIcon
						icon={
							<AddCircle
								style={{
									color:
										theme.palette.mode == "light"
											? "rgba(73, 69, 79, 1)"
											: "white",
									marginRight: "5px",
									fontSize: "18px",
								}}
							/>
						}
						text="Add Deal"
						onClick={() => {
							setOpenAddNewDeal(true);
						}}
					/>
				</Box>
			</Box>
			<Box sx={{ marginLeft: "auto" }}>
				<DateFilter />
			</Box>

			<ResponsiveGrid columnCount={1}>
				<MonthlyCapitalAdvancesChart />
				<LoanBookByCounterpartyLoansChart />
				<LoanBookByCounterpartyValueChart />
			</ResponsiveGrid>
			<ResponsiveGrid columnCount={2}>
				<CumulativeCapitalAdvancesChart />
				<RevenuesCollectedChart />
				<TotalMonthlyLoansChart />
				<CumulativeClientsPerMonthChart />
				<ClientSectorDistributionChart />
				<ClientGenderDistributionChart />
			</ResponsiveGrid>

			<CreateDealModal
				open={openAddNewDeal}
				onClose={() => setOpenAddNewDeal(false)}
				deal={selectedRows[0]}
			/>
		</Box>
	);
};

export default HomePage;
