import React from "react";
import {
	Box,
	Modal,
	Typography,
	Paper,
	Grid,
	CircularProgress,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import GreetingMessage from "../Headings/GreetingMessage";
import { useTheme } from "@mui/material";
import FundingNeed2 from "../Forms/FundingNeed2";
import { useFetchFundingLadder } from "../../../common/helpers/hooks/useDealService";
import { useFetchClientAssessment } from "../../../common/helpers/hooks/useClientService";
import FundingLadderCard from "../Cards/FundingLadderCard";
import UserAssessment from "../Widgets/UserAssessmnet";

interface ReviewDealModalProps {
	open: boolean;
	onClose: () => void;
	deal: any;
}

const DealOverviewModal: React.FC<ReviewDealModalProps> = ({
	open,
	onClose,
	deal,
}) => {
	const theme = useTheme();

	const { data: fundingLadder, isLoading: isFetchingFundingLadder } =
		useFetchFundingLadder(deal?.userId);

	const { data: userAssessment, isLoading: isFetchingAssessment } =
		useFetchClientAssessment(deal?.userId);

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					py: 2,
					px: 3,
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-start",
					textAlign: "left",
					width: "100%",
					height: "100%",
					backgroundColor: theme.palette.background.paper,
					gap: 4,
					overflow: "auto",
				}}
			>
				<Box
					sx={{ cursor: "pointer", width: "100%" }}
					onClick={onClose}
					display="flex"
					gap={1}
					alignItems="center"
				>
					<ArrowBack sx={{ fill: "rgba(73, 69, 79, 1)" }} />
					<GreetingMessage title="Back" />
				</Box>
				<UserAssessment
					userAssessment={userAssessment}
					isFetchingAssessment={isFetchingAssessment}
					deal={deal}
				/>
				<Box sx={{ width: "100%" }}>
					<Typography variant="h6" sx={{ mb: 2 }}>
						Funding Need
					</Typography>
					<FundingNeed2 selectedApplication={deal} />
				</Box>
				<Box width="100%" height="auto">
					<FundingLadderCard
						title="Funding Ladder"
						data={fundingLadder?.data?.data}
					/>
				</Box>
			</Box>
		</Modal>
	);
};

export default DealOverviewModal;
