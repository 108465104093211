import React, { useEffect, useState } from "react";
import {
	Box,
	Modal,
	Typography,
	useTheme,
	CircularProgress,
	IconButton,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
} from "@mui/material";
import toast from "react-hot-toast";
import CustomButton from "../Elements/CustomButton";
import { Close } from "@mui/icons-material";
import {
	updateDealStatus,
	updateLoanOfferStatus,
} from "../../services/retool-service"; // adjust import as needed
import { useQueryClient } from "react-query";

interface UpdateOfferStatusModalProps {
	open: boolean;
	onClose: () => void;
	offer?: any;
}

const UpdateOfferStatusModal: React.FC<UpdateOfferStatusModalProps> = ({
	open,
	onClose,
	offer,
}) => {
	const theme = useTheme();
	const [isLoading, setIsLoading] = useState(false);
	const [status, setStatus] = useState(offer?.status?.toLowerCase() || "");
	const queryClient = useQueryClient();

	const handleUpdateStatus = async () => {
		setIsLoading(true);
		const toastId = toast.loading("Updating offer status...");

		try {
			const payload = {
				offerId: offer?.id,
				status,
			};

			const response = await updateLoanOfferStatus(payload);
			const { message } = response.data;

			toast.success(message || "Offer status updated successfully!", {
				id: toastId,
			});
			queryClient.invalidateQueries("loanOffers");
			queryClient.invalidateQueries("approvedOffers");
			queryClient.invalidateQueries("loanOffersSummary");
			onClose();
		} catch (error: any) {
			toast.error(error?.response?.data?.message || "An error occurred.", {
				id: toastId,
			});
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		setStatus(offer?.status?.toLowerCase() || "");
	}, [offer]);

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "600px",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "12px",
					margin: "auto",
					overflowY: "auto",
					position: "relative",
					top: "30%",
				}}
			>
				<IconButton
					sx={{
						position: "absolute",
						top: "16px",
						right: "16px",
						color: theme.palette.grey[500],
					}}
					onClick={onClose}
				>
					<Close />
				</IconButton>

				<Typography
					variant="h6"
					mb={2}
					sx={{ color: theme.palette.text.primary }}
				>
					Update Offer Status
				</Typography>
				<Typography
					my={1}
					variant="body1"
					sx={{ color: theme.palette.text.primary }}
				>
					Email: {offer?.email}
				</Typography>

				<FormControl component="fieldset" sx={{ mb: 2 }}>
					<RadioGroup
						aria-label="deal-status"
						name="deal-status"
						value={status}
						onChange={(e) => setStatus(e.target.value)}
					>
						<FormControlLabel
							value="accepted"
							control={<Radio />}
							label="Accept"
						/>
						<FormControlLabel
							value="declined"
							control={<Radio />}
							label="Decline"
						/>
						<FormControlLabel
							value="expired"
							control={<Radio />}
							label="Expire"
						/>
						<FormControlLabel value="sent" control={<Radio />} label="Sent" />
						<FormControlLabel value="draft" control={<Radio />} label="Draft" />
					</RadioGroup>
				</FormControl>

				<Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
					{isLoading ? (
						<CircularProgress size={24} color="success" />
					) : (
						<CustomButton
							text="Update Status"
							onClick={handleUpdateStatus}
							variant="contained"
						/>
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default UpdateOfferStatusModal;
