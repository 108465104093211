import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import GreetingMessage from "../../common/components/Headings/GreetingMessage";
import BaseCard from "../../common/components/Cards/BaseCard";
import {
	AddCircle,
	Cloud,
	CloudCircleOutlined,
	Delete,
	Flag,
	Link,
	LockClock,
	People,
	PushPin,
	RequestPage,
	Visibility,
} from "@mui/icons-material";
import CustomTable from "../../common/components/Tables/CustomTable";
import { CircularProgress, Grid, Tooltip, useTheme } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import IconWithText from "../../common/components/Elements/IconWithText";
import { useLocation, useNavigate } from "react-router-dom";
import ResponsiveGrid from "../../common/components/Grids/ResponsiveGrid";
import CardWithIcon from "../../common/components/Elements/CardWithIcon";
import {
	useFetchClientAndDealSummary,
	useFetchDeals,
} from "../../common/helpers/hooks/useClientService";
import CustomSearchBar from "../../common/components/Elements/CustomSearchBar";
import AddClientModal from "../../common/components/Modals/AddClientModal";
import ProfileDetailsModal from "../../common/components/Modals/ProfileDetailsModal";
import { softDeleteDeals } from "../../common/services/retool-service"; // <-- updated method for deals
import toast from "react-hot-toast";
import ConfirmationModal from "../../common/components/Modals/ConfirmationModal";
import ClientsFloatingActionMenu from "../../common/components/Menus/ClientsFloatingActionMenu";
import DealsFloatingActionMenu from "../../common/components/Menus/DealsFloatingActionMenu";
import ReviewDealModal from "../../common/components/Modals/ReviewDealModal";
import LoadingIconButton from "../../common/components/Elements/LoadingIconButton";
import CustomTabs from "../../common/components/Elements/CustomTabs";
import BackgroundJobTable from "../../common/components/Tables/BackgroudJobTable";
import DealOverviewModal from "../../common/components/Modals/DealOverviewModal";
import KanbanBoard from "../../common/components/Widgets/Kanban";

const ManageTasksPage: React.FC = () => {
	const [openAddClientModal, setOpenAddClientModal] = useState(false);
	const [openViewDetailsModal, setOpenViewDetailsModal] = useState(false);
	const {
		data: deals,
		refetch: refetchDeals,
		isLoading: isFetching,
		isRefetching,
	} = useFetchDeals();

	const { data: summary, refetch: refetchSummary } =
		useFetchClientAndDealSummary();
	const tabs = [{ label: "Deals" }, { label: "Jobs" }];
	const [tabIndex, setTabIndex] = useState(0);

	const [selectedRow, setSelectedRow] = useState<any>(null);
	const [selectedRows, setSelectedRows] = useState<any[]>([]);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const [searchTerm, setSearchTerm] = useState("");
	const navigate = useNavigate();
	const theme = useTheme();

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const refetch = () => {
		refetchDeals();
		refetchSummary();
	};

	const [filteredData, setFilteredData] = useState([]);

	const handleDelete = (deal: any) => {
		setOpenConfirmationModal(true);
		setSelectedRow(deal);

		setConfirmationMessage(
			`Are you sure you want to delete this deal: ${deal?.email}? Deleting this deal will also remove associated (deal, offers and contracts.)`
		);
	};

	const handleDeleteDeal = async () => {
		try {
			setIsLoading(true);
			const payload = {
				Ids: [selectedRow?.id],
			};
			const response = await softDeleteDeals(payload);
			if (response.success) toast.success(response.message);
			else toast.error(response.message);
			setIsLoading(false);
			setOpenConfirmationModal(false);
			refetch();
		} catch (error) {
			toast.error("Unable to delete deal.");
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const dealsData = deals?.data?.data;

		if (dealsData) {
			const searchTermLower = searchTerm.toLowerCase();
			const newFilteredData = dealsData.filter((deal: any) => {
				return (
					(deal?.firstname?.toLowerCase().includes(searchTermLower) ?? false) ||
					(deal?.lastname?.toLowerCase().includes(searchTermLower) ?? false) ||
					(deal?.email?.toLowerCase().includes(searchTermLower) ?? false) ||
					(deal?.idNumber?.toLowerCase().includes(searchTermLower) ?? false) ||
					(deal?.sector?.toLowerCase().includes(searchTermLower) ?? false) ||
					(deal?.supplier?.toLowerCase().includes(searchTermLower) ?? false) ||
					(deal?.status?.toLowerCase().includes(searchTermLower) ?? false) ||
					(deal?.useOfFunds?.toLowerCase().includes(searchTermLower) ?? false)
				);
			});
			setFilteredData(newFilteredData);
			setSelectedRows([]);
		}
	}, [searchTerm, deals]);

	const handleView = (row: any) => {
		setSelectedRow(row);
		setOpenViewDetailsModal(true);
	};

	const location = useLocation();

	useEffect(() => {
		refetch();
	}, [location.pathname]);

	return (
		<Box
			sx={{
				py: 4,
				px: 3,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				textAlign: "center",
				width: "100%",
				gap: 2,
			}}
		>
			<GreetingMessage title="Manage Tasks" />
			<KanbanBoard />
		</Box>
	);
};

export default ManageTasksPage;
