import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import toast from "react-hot-toast";
import BaseCard from "../Cards/BaseCard";
import CustomCardHeader from "../Cards/CustomCardHeader";
import CustomSelect from "../Elements/CustomSelect";
import CustomTextInput from "../Elements/CustomTextInput";
import CustomButton from "../Elements/CustomButton";
import ResponsiveGrid from "../Grids/ResponsiveGrid";
import { saveFundingNeed } from "../../services/retool-service";
import { getProductType } from "../../helpers/utils/productUtils";
import { BusinessData } from "../../../data/businessData";

interface FundingProps {
	selectedApplication: any;
}

const FundingNeed2: React.FC<FundingProps> = ({ selectedApplication }) => {
	const [isLoading, setIsLoading] = useState<Boolean>(false);
	const [formData, setFormData] = useState({
		fundingAmount: 5000,
		duration: 5,
		useOfFunds: [],
		securityOrCollateral: "None",
		productType: "",
	});

	useEffect(() => {
		if (selectedApplication) {
			setFormData((prevFormData) => ({
				...prevFormData,
				...selectedApplication,
				useOfFunds: selectedApplication?.useOfFunds?.split(",") || [],
				productType: getProductType(selectedApplication?.loanType || ""),
			}));
		}
	}, [selectedApplication]);

	const handleInputChange = (event: any) => {
		const { name, value } = event.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleSelectChange = (name: string) => (event: any) => {
		const value = event.target.value;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		const toastId = toast.loading("Saving funding need...");

		const payload = {
			userId: selectedApplication?.client?.userId,
			fundingAmount: formData.fundingAmount,
			duration: formData.duration,
			useOfFunds: formData.useOfFunds.join(","),
			securityOrCollateral: formData.securityOrCollateral,
			productType: formData.productType,
			applicationId: selectedApplication?.id || 0,
		};

		try {
			const response = await saveFundingNeed(payload);
			toast.success(response.data.message, { id: toastId });
		} catch (error: any) {
			toast.error(error?.response?.data?.message || "An error occurred.", {
				id: toastId,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<ResponsiveGrid columnCount={1}>
				<BaseCard sx={{ padding: "20px", height: "auto" }}>
					<Box component="form">
						<CustomCardHeader title="Funding Details" />

						<CustomTextInput
							key="fundingAmount"
							name="fundingAmount"
							label="Estimated Funding Amount (Rands)"
							type="number"
							value={formData.fundingAmount}
							onChange={handleInputChange}
							sx={{ mb: 2 }}
						/>

						<CustomTextInput
							key="duration"
							name="duration"
							label="Estimated Duration (Days)"
							type="number"
							value={formData.duration}
							onChange={handleInputChange}
							sx={{ mb: 2 }}
						/>

						<CustomSelect
							name="productType"
							label="Proposed Funding Product"
							options={[
								{
									value: "short-term-input-finance",
									label: "Short Term Input Finance",
								},
								{ value: "invoice-finance", label: "Invoice Finance" },
								{ value: "purchase-order-loan", label: "Purchase Order Loan" },
								{ value: "asset-finance", label: "Asset Finance" },
								{ value: "unspecified", label: "Unspecified" },
							]}
							value={formData.productType}
							onChange={handleSelectChange("productType")}
						/>

						<CustomSelect
							name="useOfFunds"
							label="Proposed Use of Funds (select all that apply)"
							options={BusinessData().useOfFundsData.map((x: any) => ({
								label: x,
								value: x,
							}))}
							value={formData.useOfFunds}
							onChange={handleSelectChange("useOfFunds")}
							multiple
						/>

						<CustomSelect
							name="securityOrCollateral"
							label="Select security or collateral"
							options={[
								{
									value: "Contract -w/o Cession",
									label: "Contract - w/o Cession",
								},
								{
									value: "Asset(vehicle, machinery, etc)",
									label: "Asset (vehicle, machinery, etc)",
								},
								{ value: "Purchase Order", label: "Purchase Order" },
								{ value: "None", label: "None" },
							]}
							value={formData.securityOrCollateral}
							onChange={handleSelectChange("securityOrCollateral")}
						/>
					</Box>
				</BaseCard>
			</ResponsiveGrid>

			<Box mt={2} display="flex" justifyContent="center" alignItems="center">
				{isLoading ? (
					<CircularProgress size="1rem" color="success" />
				) : (
					<CustomButton
						text="Proceed"
						onClick={handleSubmit}
						variant="contained"
						color="success"
					/>
				)}
			</Box>
		</>
	);
};

export default FundingNeed2;
