import React, { useState } from "react";
import {
	RemoveRedEye,
	CheckBox,
	Upload,
	PlusOne,
	MovingOutlined,
} from "@mui/icons-material";
import FloatingActionMenu, { Action } from "../Elements/Fab";

import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import ViewDocumentsModal from "../Modals/ViewDocumentsModal";
import ValidateDocumentModal from "../Modals/ValidateDocuemntModal";
import UploadDocumentsModal from "../Modals/UploadDocumentsModal";
import PdfViewerModal from "../Modals/PdfViewModal";
import ClientRequestModal from "../Modals/ClientRequestModal";
import { useQueryClient } from "react-query";
import ClientBackgroundJobModal from "../Modals/ClientBackgroundJobModal";

interface FabProps {
	deal?: any;
	selectedRow?: any;
	selectedRows?: any[];
}

const DealsDealChecklistFloatingActionMenu: React.FC<FabProps> = ({
	deal,
	selectedRow,
	selectedRows,
}) => {
	const [viewDialogOpen, setViewDialogOpen] = useState(false);
	const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
	const [validateDialogOpen, setValidateDialogOpen] = useState(false);
	const [openPdfDialog, setOpenPdfDialog] = useState(false);
	const [pdfUrl, setPdfUrl] = useState("");
	const [openClientRequestModal, setOpenClientRequestModal] = useState(false);

	const plugin = defaultLayoutPlugin();

	const handleViewDocuments = () => {
		if (selectedRow?.uploadedDocuments?.length > 0) {
			setViewDialogOpen(true);
		} else {
			setPdfUrl(selectedRow.uploadedDocuments[0]?.downloadUrl || "");
		}
	};

	const actions: Action[] = [
		{
			label: "View Documents",
			icon: <RemoveRedEye />,
			onClick: () => setViewDialogOpen(true),
		},
		{
			label: "Client Request",
			icon: <PlusOne />,
			onClick: () => setOpenClientRequestModal(true),
		},
		{
			label: "Validate",
			icon: <CheckBox />,
			onClick: () => setValidateDialogOpen(true),
		},
		{
			label: "Upload",
			icon: <Upload />,
			onClick: () => setUploadDialogOpen(true),
		},
	];

	selectedRow.userId = deal?.userId;

	return (
		<>
			<ClientRequestModal
				deal={deal}
				doc={selectedRow}
				open={openClientRequestModal}
				handleClose={() => setOpenClientRequestModal(false)}
			/>
			<ViewDocumentsModal
				open={viewDialogOpen}
				handleClose={() => setViewDialogOpen(false)}
				selectedRow={selectedRow}
			/>

			<ValidateDocumentModal
				open={validateDialogOpen}
				handleClose={() => setValidateDialogOpen(false)}
				selectedRow={selectedRow}
			/>

			<UploadDocumentsModal
				open={uploadDialogOpen}
				handleClose={() => setUploadDialogOpen(false)}
				data={deal}
				doc={selectedRow}
			/>

			<PdfViewerModal
				open={openPdfDialog}
				handleClose={() => setOpenPdfDialog(false)}
				pdfUrl={pdfUrl}
				plugin={plugin}
			/>
			<FloatingActionMenu actions={actions} />
		</>
	);
};

export default DealsDealChecklistFloatingActionMenu;
