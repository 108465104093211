import React, { useState } from "react";
import {
	CreditScore,
	Add,
	Person,
	AccountBalance,
	Description,
	BarChart,
	Delete,
	Edit,
	Paid,
	ContentCopy,
	Send,
	MovingOutlined,
	Done,
} from "@mui/icons-material";

import FloatingActionMenu, { Action } from "../Elements/Fab";
import LinkBankAccountModal from "../Modals/LinkBankAccountModal";
import FinancialDataModal from "../Modals/FinancialDataModal";

import moment from "moment";
import useUserBankData from "../../helpers/hooks/useUserBankData";
import { useDealChecklists } from "../../helpers/hooks/useClientService";

import CreditReportModal from "../Modals/CreditReportModal";
import UpdateProfileModal from "../Modals/UpdateProfileModal";
import FundingNeedModal from "../Modals/FundingNeedModal";
import ConfirmationModal from "../Modals/ConfirmationModal";
import toast from "react-hot-toast";
import { softDeleteDeals } from "../../../common/services/retool-service";
import DealChecklistModal from "../Modals/DealChecklistModal";
import UpdateDealStatusModal from "../Modals/UpdateDealStatus";
import { useQueryClient } from "react-query";
import LoanOfferModal from "../Modals/LoanOfferModal";
import DuplicateFundingNeedModal from "../Modals/DuplicateFundingNeedModal";
import ClientRequestModal from "../Modals/ClientRequestModal";
import SendDataRequestModal from "../Modals/SendDataRequestModal";
import ProfileDetailsModal from "../Modals/ProfileDetailsModal";
import CompleteClientDetailsModal from "../Modals/CompleteClientDetailsModal";
import ClientBackgroundJobModal from "../Modals/ClientBackgroundJobModal";
import DisburseDealModal from "../Modals/DisburseDeal";

interface FabProps {
	data?: any;
	refetchData: () => void;
	selectedRows: any[];
}

const DealsFloatingActionMenu: React.FC<FabProps> = ({
	data,
	refetchData,
	selectedRows,
}) => {
	const userId = data?.userId;
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [confirmationMessage, setConfirmationMessage] =
		useState<React.ReactNode | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const queryClient = useQueryClient();

	const {
		data: clientDealchecklists,
		isLoading: isClientDealChecklistsFetching,
		refetch: refetchDealchecklists,
	} = useDealChecklists(data?.id);

	const [openOnoardingProgressModal, setOpenOnboardingProgressModal] =
		useState(false);
	const [openCreateOfferModal, setOpenCreateOfferModal] = useState(false);
	const [openRequestLinkBankModal, setOpenRequestLinkBankModal] =
		useState(false);
	const [openUpdateDealStatusModal, setOpenUpdateDealStatusModal] =
		useState(false);
	const [openViewDocumentsModal, setOpenViewDocumentsModal] = useState(false);
	const [openDuplicateDeal, setOpenDuplicateDeal] = useState(false);
	const [openDisburseDeal, setOpenDisburseDeal] = useState(false);
	const [openClientRequestModal, setOpenClientRequestModal] = useState(false);
	const [openViewDetailsModal, setOpenViewDetailsModal] = useState(false);

	const handleDelete = () => {
		setOpenConfirmationModal(true);

		const emails = selectedRows.map((row) =>
			row?.email ? row?.email : "No email, id: " + row?.id
		);

		setConfirmationMessage(
			<>
				<p>
					Are you sure you want to delete these deals? Deleting these deals will
					also remove associated:
				</p>
				<ul>
					<li>Offers</li>
					<li>Contracts</li>
				</ul>
				<p>Deals to be deleted:</p>
				<ul>
					{emails.map((email, index) => (
						<li key={index}>{email}</li>
					))}
				</ul>
			</>
		);
	};

	const handleDeleteDeal = async () => {
		try {
			setIsLoading(true);
			const payload = {
				ids: selectedRows.map((row) => row.id),
			};
			const response = await softDeleteDeals(payload);
			if (response.success) toast.success(response.message);
			else toast.error(response.message);
			setIsLoading(false);
			setOpenConfirmationModal(false);
			queryClient.invalidateQueries("deals");
			queryClient.invalidateQueries("clientAndDealSummary");
		} catch (error) {
			toast.error("Unable to delete deals.");
			setIsLoading(false);
		}
	};

	const actions: Action[] = [
		{
			label: "Onboarding Progress",
			icon: <MovingOutlined />,
			onClick: () => {
				setOpenOnboardingProgressModal(true);
			},
		},
		{
			label: "Duplicate Deal",
			icon: <ContentCopy />,
			onClick: () => {
				setOpenDuplicateDeal(true);
			},
		},
		{
			label: "Disbursement Status",
			icon: <Done />,
			onClick: () => {
				setOpenDisburseDeal(true);
			},
		},
		{
			label: "Deal Checklist",
			icon: <Description />,
			onClick: () => {
				refetchDealchecklists();
				setOpenViewDocumentsModal(true);
			},
		},
		{
			label: "Update Status",
			icon: <Edit />,
			onClick: () => {
				setOpenUpdateDealStatusModal(true);
			},
		},
		{
			label: "Delete Deal(s)",
			icon: <Delete />,
			onClick: () => {
				handleDelete();
			},
		},
		{
			label: "Send Data Request",
			icon: <Send />,
			onClick: () => setOpenClientRequestModal(true),
		},
		{
			label: "View Details",
			icon: <Person />,
			onClick: () => {
				setOpenViewDetailsModal(true);
			},
		},
	];

	return (
		<>
			<UpdateDealStatusModal
				refetch={() => refetchData()}
				open={openUpdateDealStatusModal}
				onClose={() => setOpenUpdateDealStatusModal(false)}
				deal={data}
			/>

			<DisburseDealModal
				refetch={() => refetchData()}
				open={openDisburseDeal}
				onClose={() => setOpenDisburseDeal(false)}
				deal={data}
			/>

			<LinkBankAccountModal
				open={openRequestLinkBankModal}
				onClose={() => setOpenRequestLinkBankModal(false)}
				clientEmail={data?.email}
			/>

			<DealChecklistModal
				open={openViewDocumentsModal}
				onClose={() => setOpenViewDocumentsModal(false)}
				data={clientDealchecklists?.mainData || []}
				deal={data}
				isLoading={isClientDealChecklistsFetching}
			/>
			<LoanOfferModal
				open={openCreateOfferModal}
				onClose={() => setOpenCreateOfferModal(false)}
				selectedApplication={data}
				client={data}
				type="deal"
			/>
			<FloatingActionMenu actions={actions} />
			<ConfirmationModal
				open={openConfirmationModal}
				onClose={() => setOpenConfirmationModal(false)}
				handleConfirmAction={handleDeleteDeal}
				message={confirmationMessage}
				isLoading={isLoading}
			/>
			<DuplicateFundingNeedModal
				open={openDuplicateDeal}
				onClose={() => setOpenDuplicateDeal(false)}
				selectedApplication={data}
				client={data}
			/>
			<SendDataRequestModal
				deal={data}
				open={openClientRequestModal}
				handleClose={() => setOpenClientRequestModal(false)}
			/>
			<CompleteClientDetailsModal
				open={openViewDetailsModal}
				onClose={() => setOpenViewDetailsModal(false)}
				selectedApplication={selectedRows[0]}
			/>
			<ClientBackgroundJobModal
				open={openOnoardingProgressModal}
				onClose={() => setOpenOnboardingProgressModal(false)}
				userId={data?.userId}
			/>
		</>
	);
};

export default DealsFloatingActionMenu;
