import React from "react";
import {
	ResponsiveContainer,
	BarChart,
	Bar,
	XAxis,
	YAxis,
	Tooltip,
	CartesianGrid,
	Legend,
} from "recharts";
import { Box, CircularProgress, Typography, Alert } from "@mui/material";
import { useMonthlyCapitalAdvances } from "../../../common/helpers/hooks/useFinanceService";
import BaseCard from "../Cards/BaseCard";
import NoDataFound from "../Elements/NoDataFound";
import NoDataFoundCard from "../Cards/NoDataFoundCard";

const MonthlyCapitalAdvancesChart = () => {
	const { data, isLoading, error } = useMonthlyCapitalAdvances();

	if (isLoading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height={300}
			>
				<CircularProgress color="success" />
			</Box>
		);
	}

	if (error) {
		return (
			<Alert severity="error">
				Failed to load data. Please try again later.
			</Alert>
		);
	}

	if (!data || data?.monthlyCapitalAdvance?.length === 0) {
		return <NoDataFoundCard title="Monthly Capital" />;
	}

	return (
		<BaseCard sx={{ height: "auto", padding: "20px 10px" }}>
			<Typography variant="body2" fontWeight={500} gutterBottom>
				Monthly Capital Advances
			</Typography>
			<ResponsiveContainer width="100%" height={300}>
				<BarChart
					data={data?.monthlyCapitalAdvance}
					margin={{ top: 20, right: 20, left: 20, bottom: 10 }}
				>
					<XAxis
						dataKey="month"
						fontSize={14}
						padding={{ left: 20, right: 20 }}
					/>
					<YAxis
						fontSize={12}
						tickFormatter={(value: number) =>
							value >= 1_000_000
								? `R${(value / 1_000_000).toFixed(1)}M`
								: value >= 1_000
									? `R${(value / 1_000).toFixed(1)}K`
									: `R${value}`
						}
						label={{
							value: "Amount (R)",
							angle: -90,
							position: "insideLeft",
							fontSize: 14,
							dx: -15,
						}}
					/>
					<Tooltip
						formatter={(value: number) => `R ${value.toLocaleString()}`}
					/>

					<Bar
						dataKey="amount"
						fill="#619C9C"
						barSize={40}
						label={{
							position: "top",
							content: (props: any) => {
								const { x, y, value, width } = props;
								const formattedValue =
									value >= 1_000_000
										? `R ${(value / 1_000_000).toFixed(1)}M`
										: value >= 1_000
											? `R ${(value / 1_000).toFixed(1)}K`
											: `R ${value}`;
								return (
									<text
										x={x + width / 2}
										y={y - 5}
										textAnchor="middle"
										fill="#000"
										fontSize="12"
									>
										{formattedValue}
									</text>
								);
							},
						}}
					/>
				</BarChart>
			</ResponsiveContainer>
			<Typography variant="body2" fontWeight={500} gutterBottom>
				Date (Month - Year)
			</Typography>
		</BaseCard>
	);
};

export default MonthlyCapitalAdvancesChart;
