import React from "react";
import {
	ResponsiveContainer,
	BarChart,
	Bar,
	LineChart,
	Line,
	XAxis,
	YAxis,
	Tooltip,
	CartesianGrid,
	Legend,
} from "recharts";
import { Box, CircularProgress, Typography, Alert } from "@mui/material";
import { useRevenuesCollected } from "../../../common/helpers/hooks/useFinanceService";
import BaseCard from "../Cards/BaseCard";
import NoDataFoundCard from "../Cards/NoDataFoundCard";

const RevenuesCollectedChart = () => {
	const { data, isLoading, error } = useRevenuesCollected();

	if (isLoading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height={300}
			>
				<CircularProgress />
			</Box>
		);
	}

	if (error) {
		return (
			<Alert severity="error">
				Failed to load data. Please try again later.
			</Alert>
		);
	}

	if (!data || data?.revenuesCollected?.length === 0) {
		return <NoDataFoundCard title="Revenues Collected" />;
	}

	const chartData = data?.revenuesCollected?.map((item: any) => ({
		month: item.month,
		interestRevenue: item.interestRevenue,
		nonInterestRevenue: item.nonInterestRevenue,
		totalRevenue: item.total,
	}));

	return (
		<BaseCard sx={{ height: "auto", padding: "20px 10px" }}>
			<Typography variant="body2" fontWeight={500} gutterBottom>
				Revenues Collected
			</Typography>
			<ResponsiveContainer width="100%" height={300}>
				<BarChart
					data={chartData}
					margin={{ top: 20, right: 20, left: 20, bottom: 10 }}
				>
					<XAxis dataKey="month" fontSize={14} />
					<YAxis
						dataKey="totalRevenue"
						fontSize={12}
						tickFormatter={(value: number) =>
							value >= 1_000_000
								? `R ${(value / 1_000_000).toFixed(1)}M`
								: value >= 1_000
									? `R ${(value / 1_000).toFixed(1)}K`
									: `R ${value}`
						}
						label={{
							value: "Amount (R)",
							angle: -90,
							position: "insideLeft",
							fontSize: 14,
							dx: -15,
						}}
					/>
					<Tooltip
						formatter={(value: number) => `R ${value.toLocaleString()}`}
					/>
					<Legend
						formatter={(value) => {
							if (value === "interestRevenue") {
								return "Interest Revenue";
							}
							if (value === "nonInterestRevenue") {
								return "Non-Interest Revenue";
							}
							if (value === "totalRevenue") {
								return "Total Revenue";
							}
							return value;
						}}
						layout="horizontal"
						align="center"
						verticalAlign="top"
					/>
					<Bar dataKey="interestRevenue" stackId="a" fill="#619C9C" />
					<Bar dataKey="nonInterestRevenue" stackId="a" fill="#82ca9d" />
				</BarChart>
				<LineChart
					data={chartData}
					margin={{ top: 20, right: 20, left: 20, bottom: 10 }}
				>
					<Line type="monotone" dataKey="totalRevenue" stroke="#ff7300" />
				</LineChart>
			</ResponsiveContainer>
			<Typography variant="body2" fontWeight={500} gutterBottom>
				Date (Month - Year)
			</Typography>
		</BaseCard>
	);
};

export default RevenuesCollectedChart;
