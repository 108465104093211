import React, { useState } from "react";
import {
	Box,
	Modal,
	Typography,
	useTheme,
	CircularProgress,
	IconButton,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import toast from "react-hot-toast";
import CustomButton from "../Elements/CustomButton";
import { Close } from "@mui/icons-material";
import { useQueryClient } from "react-query";
import { disburseDeal } from "../../../common/services/finance-service";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

interface DisburseDealModalProps {
	open: boolean;
	onClose: () => void;
	refetch: () => void;
	deal?: any;
}

const DisburseDealModal: React.FC<DisburseDealModalProps> = ({
	open,
	onClose,
	refetch,
	deal,
}) => {
	const theme = useTheme();
	const [isLoading, setIsLoading] = useState(false);
	const [isDisbursed, setIsDisbursed] = useState(!!deal?.isDisbursed);
	const [disbursementDate, setDisbursementDate] =
		useState<moment.Moment | null>(
			deal?.disbursementDate ? moment(deal.disbursementDate) : null
		);

	const queryClient = useQueryClient();

	const handleDisburseDeal = async () => {
		setIsLoading(true);
		const toastId = toast.loading("Disbursing deal...");

		try {
			if (!isDisbursed && !disbursementDate) {
				toast.error("Please select a disbursement date.", { id: toastId });
				return;
			}

			const payload = {
				id: deal?.id,
				isDisbursed: true,
				disbursementDate: isDisbursed ? disbursementDate?.toISOString() : null,
			};

			const response = await disburseDeal(payload);

			if (response.success) {
				toast.success(response.message, { id: toastId });

				refetch();
			} else toast.error(response.message, { id: toastId });

			onClose();
		} catch (error: any) {
			const errorMessage =
				error?.response?.data?.message || "An error occurred.";
			toast.error(errorMessage, { id: toastId });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "600px",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "12px",
					margin: "auto",
					overflowY: "auto",
					position: "relative",
					top: "30%",
				}}
			>
				<IconButton
					sx={{
						position: "absolute",
						top: "16px",
						right: "16px",
						color: theme.palette.grey[500],
					}}
					onClick={onClose}
				>
					<Close />
				</IconButton>

				<Typography
					variant="body2"
					mb={4}
					sx={{ color: theme.palette.text.primary }}
				>
					Update Disbursement Status: {deal?.email}
				</Typography>

				<Box my={2}>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							label="Disbursement Date"
							value={disbursementDate}
							onChange={(newValue) => setDisbursementDate(newValue)}
							minDate={moment()}
							disabled={!isDisbursed}
							sx={{ mb: 2, width: "100%" }}
						/>
					</LocalizationProvider>
				</Box>

				<Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
					{isLoading ? (
						<CircularProgress size={24} color="success" />
					) : (
						<CustomButton
							text="Submit"
							onClick={handleDisburseDeal}
							variant="contained"
						/>
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default DisburseDealModal;
