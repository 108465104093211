import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab, Checkbox, Typography, Divider } from "@mui/material";
import BaseCard from "./BaseCard";
import CustomCardHeaderWithIcon from "./CustomCardHeaderWithIcon";
import { useTheme } from "@mui/material/styles";
import BackgroundJobModal from "../Modals/BackgroundJobModal";
import SecondaryText from "../Elements/SecondaryText";

interface Log {
	text: string;
	alternativeText?: string;
	checked?: boolean;
}

interface ListCardProps {
	title: string;
	titleIcon?: React.ReactNode;
	logs: { [key: string]: Log[] };
	showCheckboxes?: boolean;
	tabs?: boolean;
	alternativeTextInColumn?: boolean;
	autoHeight?: boolean;
}

const ListCard: React.FC<ListCardProps> = ({
	title,
	titleIcon,
	logs = {},
	showCheckboxes,
	tabs,
	alternativeTextInColumn = false,
	autoHeight = false,
}) => {
	const [selectedTab, setSelectedTab] = useState("");
	const theme = useTheme();
	const [open, setOpen] = useState(false);

	useEffect(() => {
		const keys = Object.keys(logs);
		if (keys.length > 0) {
			setSelectedTab(keys[0]);
		}
	}, [logs]);

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue);
	};

	return (
		<BaseCard sx={{ height: autoHeight ? "auto" : "220px" }}>
			<CustomCardHeaderWithIcon title={title} icon={titleIcon} />
			{tabs && (
				<Tabs
					sx={{
						justifyContent: "center",
						marginLeft: "150px",
						marginTop: "-70px",
						marginBottom: "30px",
						"& .MuiTabs-indicator": {
							backgroundColor: "#619C9C",
						},
					}}
					value={selectedTab}
					onChange={handleTabChange}
				>
					{Object.keys(logs).map((tabKey) => (
						<Tab
							key={tabKey}
							label={tabKey}
							value={tabKey}
							sx={{
								fontSize: "14px",
								textTransform: "capitalize",
								fontWeight: 500,
								color: theme.palette.mode === "dark" ? "#FFFFFF" : "#1E1E1E",
								"&.Mui-selected": {
									color: theme.palette.mode === "dark" ? "#FFFFFF" : "#1E1E1E",
								},
							}}
						/>
					))}
				</Tabs>
			)}

			<Box
				mt={-3}
				p={1}
				onClick={() => setOpen(true)}
				sx={{ cursor: "pointer" }}
			>
				{logs[selectedTab]?.map((log, index) => (
					<React.Fragment key={index}>
						<Box display="flex" alignItems="center" p={0.4}>
							{showCheckboxes && (
								<Checkbox
									color="success"
									sx={{ marginLeft: "-10px" }}
									checked={log.checked}
								/>
							)}
							<Box
								display="flex"
								flexDirection={alternativeTextInColumn ? "column" : "column"}
								flexGrow={1}
							>
								<Typography
									color={theme.palette.mode === "dark" ? "#FFFFFF" : "#000000"}
									textAlign="left"
									fontSize="16px"
									lineHeight="21px"
									fontWeight="400"
									letterSpacing="-0.0031em"
								>
									{log.text}
								</Typography>
								{log.alternativeText && (
									<SecondaryText>{log.alternativeText}</SecondaryText>
								)}
							</Box>
						</Box>
						{index < logs[selectedTab].length - 1 && (
							<Divider sx={{ my: 0.1 }} />
						)}
					</React.Fragment>
				))}
			</Box>
			<BackgroundJobModal open={open} onClose={() => setOpen(false)} />
		</BaseCard>
	);
};

export default ListCard;
