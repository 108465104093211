import { clientsDummyData } from "./clients";
import PushPin from "@mui/icons-material/PushPin";
import transactionsDummyData from "./transactions";

export const greetingMessageProps = {
	title: "Hi Ben!",
	subtitle: "Pin the insights you want to see on your dashboard",
};

export const infoRiskCardProps = {
	title: "Flags",
	circleSize: 40,
	circleInnerText: "6",
	circleBesideText: "Flagged transactions",
	circleColor: "#B3261E",
	dottedColor: "#EC221F",
	dottedSize: "8px",
	additionalLabels: [
		"Transaction over R 500,000.00",
		"Merchant contains “BMW”",
		"Transaction contains “Lotto”",
		"",
	],
	labelText: "+3 more",
	labelColor: "#3D7F8E",
};

export const listCardPropsActivityLog = {
	title: "Activity Log",
	logs: {
		Activity: [
			{ text: "Bank account linked", alternativeText: "" },
			{ text: "Repayment processed", alternativeText: "" },
			{ text: "Risk status changed from low to medium", alternativeText: "" },
		],
	},
	showCheckboxes: false,
	tabs: false,
};

export const summaryCardClientsProps = {
	title: "Clients",
	leftPrimaryHeading: "22 active clients",
	rightPrimaryHeading: "2 High-risk clients",
	showPill: true,
	pillInnerText: "+2",
	pillBesideText: "Over the last 30 days",
	dottedColor: "#EC221F",
	dottedSize: "8px",
	pillBgColor: "white",
	additionalLeftLabels: [],
	additionalRightLabels: ["1 Non-performing loan", "1 Default risk"],
};

export const summaryCardHistoryProps = {
	title: "History",
	leftPrimaryHeading: "105 Loans disbursed",
	rightPrimaryHeading: "R 9,005,987.00 loaned",
	leftSecondaryText: "Since dd/mm/yyyy (join date)",
	rightSecondaryText: "Since dd/mm/yyyy (join date)",
	showPill: false,
	dottedColor: "#2C2C2C",
	dottedSize: "8px",
	pillBgColor: "white",
	additionalLeftLabels: ["9 Currently active"],
	additionalRightLabels: ["R 780 995.00 currently loaned"],
};

export const chartSummaryCardLoanPerformanceProps = {
	title: "Loan Performance",
	dottedColors: ["#6EAD57", "#3D7F8E", "#619C9C"],
	dottedSize: "8px",
	additionalRightLabels: [
		"34% of high performing",
		"46% of Average performing",
		"20% of Non performing",
	],
	type: "pie",
	data: [34, 46, 20],
};

export const chartSummaryCardPortfolioDiversityProps = {
	title: "Portfolio diversity",
	dottedColors: ["#6EAD57", "#3D7F8E", "#619C9C"],
	dottedSize: "8px",
	additionalRightLabels: [
		"34% of high performing",
		"46% of Average performing",
		"20% of Non performing",
	],
	type: "pie",
	data: [34, 46, 20],
};

export const chartSummaryCardLoanBreakdownProps = {
	title: "Loan breakdown",
	dottedColors: ["#6EAD57", "#3D7F8E"],
	dottedSize: "8px",
	rightPrimaryHeading: "R 9,005,987.00",
	rightSecondaryText: "Total loans",
	additionalRightLabels: ["Active: R7,080,995.00", "Paid-up: R 1,924,992.00"],
	type: "pie",
	data: [34, 46, 20],
};

export const chartSummaryCardLoanRatiosProps = {
	title: "Loan ratios",
	dottedColors: ["#6EAD57", "#3D7F8E"],
	dottedSize: "8px",
	rightPrimaryHeading: "R 7,080,995.00",
	rightSecondaryText: "Active",
	additionalRightLabels: [
		"Repaid: R 6,192,990.00",
		"In progress: R 1,924,992.00",
	],
	type: "pie",
	data: [34, 46, 20],
};

export const taskCardProps = {
	title: "Task",
	tasks: [
		{
			status: "Todo",
			taskCount: 9,
			highPriorityCount: 1,
			lowPriorityCount: 1,
			dueTodayCount: 2,
		},
		{
			status: "In-Progress",
			taskCount: 5,
			highPriorityCount: 0,
			lowPriorityCount: 1,
			dueTodayCount: 1,
		},
		{
			status: "Completed",
			taskCount: 12,
			highPriorityCount: 0,
			lowPriorityCount: 1,
			dueTodayCount: 0,
		},
	],
};

export const listCardPropsTaskList = {
	title: "Task List",
	logs: {
		Todo: [
			{
				text: "1/4 Follow-up with (Client name) on contract...",
				alternativeText: "Today",
				checked: false,
			},
			{
				text: "2/4 Conclude contract for (Client name)",
				alternativeText: "Tomorrow",
				checked: true,
			},
		],
		"In-Progress": [
			{
				text: "3/4 Process failed payment for (Client)",
				alternativeText: "dd/mm/yyyy",
				checked: false,
			},
		],
		Done: [],
	},
	showCheckboxes: true,
	tabs: true,
};

export const lineChartSummaryCardRepaymentHistoryProps = {
	title: "Repayment History",
	data: {
		data1: [
			500000, 800000, 900000, 600000, 750000, 1000000, 1200000, 900000, 950000,
			850000, 900000, 1000000,
		],
		data2: [
			400000, 600000, 1000000, 500000, 800000, 1200000, 1300000, 1000000,
			950000, 950000, 1100000, 900000,
		],
	},
	maxValue: 1300000,
};

export const lineChartSummaryCardCollectiveFinancialRecordsProps = {
	title: "Repayment History",
	data: {
		data1: [
			500000, 800000, 900000, 600000, 750000, 1000000, 1200000, 900000, 950000,
			850000, 900000, 1000000,
		],
		data2: [
			400000, 600000, 1000000, 500000, 800000, 1200000, 1300000, 1000000,
			950000, 950000, 1100000, 900000,
		],
	},
	maxValue: 1300000,
};

export const incomeAndfinanceCardProps = {
	title: "Income and expenses",
	total: 95000,
	income: 95000,
	expense: 95000,
};

export const debtServiceCoverRatioRatingCardProps = {
	title: "Debt service cover ratio",
	highest: 5.91,
	lowest: 1.03,
	average: 2.62,
	percentageIncrease: 2.5,
	clientWithHighestValue: "Benard",
	clientWithLowestValue: "Jerome",
};

export const portfolioBusinessSizeDoughnutSummaryCardProps = {
	title: "Portfolio business sizes",
	additionalRightLabels: [
		{
			heading: "60% Micro",
			subtitle: "Fewer than 10 employees, annual turnover less than R5 million",
		},
		{
			heading: "30% Small",
			subtitle:
				"Fewer than 50 employees, annual turnover less than R25 million",
		},
		{
			heading: "10% Medium",
			subtitle:
				"Fewer than 200 employees, annual turnover less than R51 million",
		},
	],
	dottedColors: ["#5C6138", "#3D7F8E", "#6EAD57"],
	dottedSize: "10px",
	data: [60, 10, 30],
};

export const topIndustriesColoredBoxSummaryCardProps = {
	title: "Top 3 industries",
	others: ["Construction", "Fashion", "Accommodation", "Carpentary"],
	segments: [
		{ name: "Agriculture", value: 30, color: "#3D7F8E" },
		{ name: "Automotive", value: 20, color: "#619C9C" },
		{ name: "Health", value: 40, color: "#6EAD57" },
		{ name: "Others", value: 10, color: "#525459" },
	],
};

export const provincialPresenceColoredBoxSummaryCard2Props = {
	title: "Provincial presence",
	segments: [
		{ name: "Eastern Cape", value: 9 },
		{ name: "Free State", value: 7 },
		{ name: "Gauteng", value: 5 },
		{ name: "KwaZulu-Natal", value: 4 },
		{ name: "Limpopo", value: 2 },
		{ name: "Mpumalanga", value: 2 },
		{ name: "Northern Cape", value: 1 },
		{ name: "North West", value: 1 },
	],
};

export const clientListProps = {
	title: "New Clients",
	data: [
		{
			title: "Step 1: Onboarding",
			subtitle: "Add client to the platform",
			clients: ["Client A", "Client B", "Client C"],
		},
		{
			title: "Step 2: Link data",
			subtitle: "Upload or link data sources",
			clients: ["Client D", "Client E"],
		},
		{
			title: "Step 3: Onboarding",
			subtitle: "Set up flags to monitor transactions",
			clients: ["Client F", "Client G", "Client H", "Client I"],
		},
	],
};

export const riskHistoryProps = {
	title: "Risk history",
	data: {
		low: [1, 0, 0, 1, 0, 2, 1, 0, 1, 0, 0, 1],
		medium: [0, 2, 0, 1, 2, 0, 0, 3, 0, 1, 2, 0],
		high: [0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 2],
	},
};

export const profileRiskStatusProps = {
	title: "Profile risk status",
	risk: "Medium",
	previousRisk: "Low",
	previousRiskDate: "2024/04/04",
};

export const pinnedClientTableProps = {
	data: clientsDummyData,
	title: "Pinned Clients",
	columns: [
		{ title: "Company", key: "company", sortable: true },
		{ title: "Contact", key: "contact", sortable: true },
		{ title: "Active Since", key: "activeSince", sortable: true },
		{ title: "Borrowed", key: "borrowed", sortable: true },
		{ title: "Remaining Term", key: "remainingTerm", sortable: true },
		{ title: "Tasks", key: "tasks", sortable: true },
		{ title: "Risk Status", key: "riskStatus", sortable: true },
		{ title: "Flags", key: "flags", sortable: true },
	],
	actionColumn: {
		title: "Pinned",
		icon: <PushPin sx={{ fill: "#000" }} />,
	},
};

export const flaggedTransactionsTableProps = {
	data: transactionsDummyData,
	title: "Flagged Transactions",
	columns: [
		{ title: "Company", key: "company", sortable: true },
		{ title: "Date", key: "date", sortable: true },
		{ title: "Description", key: "description", sortable: true },
		{ title: "Amount", key: "amount", sortable: true },
		{ title: "Flag", key: "flag", sortable: true },
		{ title: "Flag Details", key: "flagDetails", sortable: true },
	],
};
