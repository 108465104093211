import React, { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import CustomSearchBar from "../Elements/CustomSearchBar";
import CustomTable from "./CustomTable";
import {
	useFetchBackgroundJobs,
	useFetchClientBackgroundJobs,
} from "../../../common/helpers/hooks/useClientService";

const ClientBackgroundJobTable = ({ userId }) => {
	const theme = useTheme();

	const [searchTerm, setSearchTerm] = useState("");
	const [filteredData, setFilteredData] = useState([]);
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedRows, setSelectedRows] = useState([]);

	const {
		data: backgroundJobs = [],
		isLoading,
		refetch,
	} = useFetchClientBackgroundJobs(userId);

	useEffect(() => {
		if (backgroundJobs?.data?.data) {
			const data = backgroundJobs?.data?.data;

			const lowerCaseSearchTerm = searchTerm?.toString().toLowerCase() || "";

			const searchResults = data.filter((job) =>
				["email", "type", "status", "description"].some((key) =>
					job[key]?.toString().toLowerCase().includes(lowerCaseSearchTerm)
				)
			);
			setFilteredData(searchResults);
		}
	}, [searchTerm, backgroundJobs]);

	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
	};

	useEffect(() => {
		const data = backgroundJobs?.data?.data;
		setFilteredData(data ? data : []);
	}, [backgroundJobs]);

	return (
		<Box
			display="flex"
			flexDirection="column"
			gap={2}
			position="relative"
			minHeight="100vh"
		>
			<CustomSearchBar
				searchTerm={searchTerm}
				onSearchChange={handleSearchChange}
			/>
			<CustomTable
				isLoading={isLoading}
				columns={[
					{ title: "Email", key: "email", sortable: true },
					{ title: "Type", key: "type", sortable: true, isPill: true },
					{ title: "Status", key: "status", sortable: true, isPill: true },
					{ title: "Description", key: "description", sortable: true },
					{ title: "Created", key: "createdTimestamp", sortable: true },
					{ title: "Updated", key: "lastUpdatedTimestamp", sortable: true },
				]}
				data={filteredData ? filteredData : []}
				selectable
				onRowSelect={(rows) => {
					setSelectedRows(rows);
					setSelectedRow(rows[rows ? rows.length - 1 : 0]);
				}}
				selected={selectedRows}
			/>
		</Box>
	);
};

export default ClientBackgroundJobTable;
