import React from "react";
import {
	ResponsiveContainer,
	BarChart,
	Bar,
	XAxis,
	YAxis,
	Tooltip,
	CartesianGrid,
} from "recharts";
import { Box, CircularProgress, Typography, Alert } from "@mui/material";
import { useTotalMonthlyLoans } from "../../../common/helpers/hooks/useFinanceService";
import BaseCard from "../Cards/BaseCard";
import NoDataFoundCard from "../Cards/NoDataFoundCard";

const TotalMonthlyLoansChart = () => {
	const { data, isLoading, error } = useTotalMonthlyLoans();

	if (isLoading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height={300}
			>
				<CircularProgress />
			</Box>
		);
	}

	if (error) {
		return (
			<Alert severity="error">
				Failed to load data. Please try again later.
			</Alert>
		);
	}

	if (!data || data?.loans?.length === 0) {
		return <NoDataFoundCard title="Total Monthly Loans" />;
	}

	const chartData = data?.loans?.map((item: any) => ({
		month: item.month,
		noOfLoan: item.total,
		clientName: item.clientName,
		percentage: item.percentage,
		sector: item.sector,
	}));

	return (
		<BaseCard sx={{ height: "auto", padding: "20px 10px" }}>
			<Typography variant="body2" fontWeight={500} gutterBottom>
				Total Monthly Loans
			</Typography>
			<ResponsiveContainer width="100%" height={300}>
				<BarChart
					data={chartData}
					margin={{ top: 20, right: 20, left: 20, bottom: 10 }}
				>
					<XAxis dataKey="month" fontSize={14} />
					<YAxis
						dataKey="noOfLoan"
						fontSize={12}
						label={{
							value: "Number of loans",
							angle: -90,
							position: "insideLeft",
							fontSize: 14,
							dx: -10,
						}}
					/>
					<Tooltip
						formatter={(value: number, name: string, props: any) => {
							if (name === "noOfLoan") {
								return `${value.toLocaleString()}`;
							}
						}}
					/>
					<Bar dataKey="noOfLoan" fill="#619C9C" />
				</BarChart>
			</ResponsiveContainer>
			<Typography variant="body2" fontWeight={500} gutterBottom>
				Date (Month - Year)
			</Typography>
		</BaseCard>
	);
};

export default TotalMonthlyLoansChart;
