import { useQuery, useMutation } from 'react-query';
import {
    getClients,
    getClientDeals,
    softDeleteDeals,
    getClientAndDealSummary,
    updateUserAndBusinessProfile,
    exportTransactionHistories,
    exportMultipleTransactionHistories,
    runKycUsingEmail,
    runKybUsingEmail,
    addUser,
    getClientDealChecklists,
    getPendingApplications,
    getDealChecklist,
    getLendingApplications,
    getBackgroundJobs,
    getFundingLadder
} from '../../services/retool-service';
import { useUserId } from './useUserService';


export const useFetchFundingLadder = (userId:string) => {
   
    return useQuery(['fundingLadder', userId], () => getFundingLadder(userId), {
        enabled: !!userId
    });
};


