import React from "react";
import { Box } from "@mui/material";
import BaseCard from "./BaseCard";
import CustomCardHeaderWithIcon from "./CustomCardHeaderWithIcon";
import BarChartWrapper from "../Charts/BarChartWrapper";
import NoDataFoundCard from "./NoDataFoundCard";

interface SummaryCardProps {
	title: string;
	titleIcon?: React.ReactNode;
	data: { low: number[]; medium: number[]; high: number[] };
}

const BarChartSummaryCard: React.FC<SummaryCardProps> = ({
	title,
	titleIcon,
	data,
}) => {
	const labels = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	const combinedData = labels.map((_, index) => {
		if (data.high[index] > 0) return 3;
		if (data.medium[index] > 0) return 2;
		if (data.low[index] > 0) return 1;
		return 0;
	});

	const hasData = data.low.length || data.medium.length || data.high.length;

	return (
		<BaseCard>
			<CustomCardHeaderWithIcon title={title} icon={titleIcon} />
			<Box display="flex" justifyContent="space-between" p={2} mt={-3}>
				<Box width="100%" height="100%" sx={{ height: "150px" }}>
					{hasData ? (
						<BarChartWrapper
							chartData={{
								labels: labels,
								datasets: [
									{
										label: "Risk Levels",
										data: combinedData,
										backgroundColor: combinedData.map((value) => {
											if (value === 1) return "#14AE5C";
											if (value === 2) return "#F5C400";
											if (value === 3) return "#EC221F";
											return "transparent";
										}),
										borderColor: "black",
										borderWidth: 2,
										barPercentage: 1,
										categoryPercentage: 1,
										maxBarThickness: Infinity,
									},
								],
							}}
							options={{
								responsive: true,
								maintainAspectRatio: false,
								scales: {
									x: {
										title: {
											display: true,
											text: "Months",
										},
										grid: {
											display: true,
										},
										barPercentage: 1,
										categoryPercentage: 1,
									},
									y: {
										title: {
											display: true,
											text: "Risk Level",
										},
										ticks: {
											callback: function (value: number) {
												return value === 1
													? "Low"
													: value === 2
														? "Medium"
														: value === 3
															? "High"
															: "";
											},
										},
										beginAtZero: true,
										max: 3,
										grid: {
											display: true,
										},
									},
								},
								plugins: {
									legend: {
										display: false,
									},
									tooltip: {
										enabled: true,
									},
								},
							}}
						/>
					) : (
						<NoDataFoundCard title={title} />
					)}
				</Box>
			</Box>
		</BaseCard>
	);
};

export default BarChartSummaryCard;
