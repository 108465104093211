import React from "react";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const CustomFab = ({ text, action }) => {
	return (
		<Box
			sx={{
				position: "fixed",
				bottom: 16,
				right: 16,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Fab
				variant="extended"
				onClick={action}
				aria-label="proceed"
				sx={{
					textTransform: "none",
					fontWeight: "bold",
					backgroundColor: "rgba(61, 127, 142, 1)",
					color: "white",
					"&:hover": {
						backgroundColor: "rgba(61, 127, 142, 1)",
					},
				}}
			>
				{text} <ArrowForwardIcon sx={{ ml: 1 }} />
			</Fab>
		</Box>
	);
};

export default CustomFab;
