import React, { FC } from "react";
import { Chip, ChipProps } from "@mui/material";

interface StatusChipProps extends ChipProps {
	value: string;
}

const StatusChip: FC<StatusChipProps> = ({ value, ...props }) => {
	const getColor = (value: string) => {
		switch (value) {
			case "Uploaded":
			case "Yes":
			case "Accepted":
				return { chipColor: "success" };
			case "Sent":
				return { chipColor: "primary" };
			case "Pending":
			case "No":
			case "False":
			case "Failed":
			case "Declined":
			case "Rejected":
				return { chipColor: "error" };
			case "InProgress":
				return { customColor: { backgroundColor: "#FFCC00", color: "black" } };
			case "Submitted":
				return { customColor: { backgroundColor: "#1976D2", color: "white" } };
			case "Reviewed":
				return { customColor: { backgroundColor: "#6A1B9A", color: "white" } };
			case "Approved":
				return { customColor: { backgroundColor: "#619C9C", color: "white" } };
			case "Cancelled":
				return { customColor: { backgroundColor: "#616161", color: "white" } };
			case "Expired":
				return { customColor: { backgroundColor: "orange", color: "white" } };
			case "Abandoned":
				return { customColor: { backgroundColor: "#8E24AA", color: "white" } };
			case "PreSubmitted":
				return { customColor: { backgroundColor: "#0277BD", color: "white" } };
			case "PaidUp":
				return { customColor: { backgroundColor: "#4CAF50", color: "white" } };
			case "Draft":
				return { customColor: { backgroundColor: "#BDBDBD", color: "black" } };
			case "Potential High Risk":
				return { customColor: { backgroundColor: "#C8102E", color: "white" } };
			case "Medium Risk":
				return { customColor: { backgroundColor: "#FFA500", color: "white" } };
			case "Low Risk":
				return { customColor: { backgroundColor: "#4CAF50", color: "white" } };
			case "1 - Extreme Risk":
				return { customColor: { backgroundColor: "#C8102E", color: "white" } };
			case "2 - Severe Risk":
				return { customColor: { backgroundColor: "#D32F2F", color: "white" } };
			case "3 - Mild Risk":
				return { customColor: { backgroundColor: "#FFA500", color: "white" } };
			case "4 - Moderate Risk":
				return { customColor: { backgroundColor: "#FFCC00", color: "black" } };
			case "5 - Minimal Risk":
				return { customColor: { backgroundColor: "#4CAF50", color: "white" } };
			default:
				return { chipColor: "default" };
		}
	};

	const { chipColor, customColor } = getColor(value);

	return (
		<Chip
			label={value}
			sx={{
				...(customColor ? customColor : {}),
			}}
			color={chipColor as ChipProps["color"]}
			{...props}
		/>
	);
};

export default StatusChip;
