import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import toast from "react-hot-toast";
import BaseCard from "../Cards/BaseCard";
import CustomCardHeader from "../Cards/CustomCardHeader";
import CustomSelect from "../Elements/CustomSelect";
import CustomTextInput from "../Elements/CustomTextInput";
import CustomButton from "../Elements/CustomButton";
import ResponsiveGrid from "../Grids/ResponsiveGrid";
import { saveFundingNeed } from "../../services/retool-service";
import { getProductType } from "../../helpers/utils/productUtils";
import { BusinessData } from "../../../data/businessData";

const FundingNeed3 = ({
	formData,
	setFormData,
	handleNext,
	isSubmit = false,
	client,
}) => {
	const [isLoading, setIsLoading] = useState(false);

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleSelectChange = (name) => (event) => {
		const value = event.target.value;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleSave = async () => {
		setIsLoading(true);
		const toastId = toast.loading("Saving funding need...");

		const payload = {
			userId: client?.userId,
			fundingAmount: formData.fundingAmount,
			duration: formData.duration,
			useOfFunds: formData.useOfFunds.join(","),
			securityOrCollateral: formData.securityOrCollateral,
			productType: formData.productType,
			applicationId: 0,
		};

		try {
			const response = await saveFundingNeed(payload);
			toast.success(response.data.message, { id: toastId });
			setFormData({
				fundingAmount: 5000,
				duration: 5,
				useOfFunds: [],
				securityOrCollateral: "None",
				productType: "",
			});
			handleNext?.();
		} catch (error) {
			toast.error(error?.response?.data?.message || "An error occurred.", {
				id: toastId,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const handleProceed = () => {
		if (isSubmit) {
			handleSave();
		} else {
			if (handleNext) handleNext();
		}
	};

	return (
		<>
			<ResponsiveGrid columnCount={1}>
				<BaseCard sx={{ padding: "20px", height: "auto" }}>
					<Box component="form">
						<CustomCardHeader title={`Funding Details: ${client?.email}`} />
						<CustomTextInput
							key="fundingAmount"
							name="fundingAmount"
							label="Estimated Funding Amount (Rands)"
							type="number"
							value={formData.fundingAmount}
							onChange={handleInputChange}
							sx={{ mb: 2 }}
						/>

						<CustomTextInput
							key="duration"
							name="duration"
							label="Estimated Duration (Days)"
							type="number"
							value={formData.duration}
							onChange={handleInputChange}
							sx={{ mb: 2 }}
						/>

						<CustomSelect
							name="productType"
							label="Proposed Funding Product"
							options={[
								{
									value: "short-term-input-finance",
									label: "Short Term Input Finance",
								},
								{ value: "invoice-finance", label: "Invoice Finance" },
								{ value: "purchase-order-loan", label: "Purchase Order Loan" },
								{ value: "asset-finance", label: "Asset Finance" },
								{ value: "unspecified", label: "Unspecified" },
							]}
							value={formData.productType}
							onChange={handleSelectChange("productType")}
						/>

						<CustomSelect
							name="useOfFunds"
							label="Proposed Use of Funds (select all that apply)"
							options={BusinessData().useOfFundsData.map((x) => ({
								label: x,
								value: x,
							}))}
							value={formData.useOfFunds}
							onChange={handleSelectChange("useOfFunds")}
							multiple
						/>

						<CustomSelect
							name="securityOrCollateral"
							label="Select security or collateral"
							options={[
								{
									value: "Contract -w/o Cession",
									label: "Contract - w/o Cession",
								},
								{
									value: "Asset(vehicle, machinery, etc)",
									label: "Asset (vehicle, machinery, etc)",
								},
								{ value: "Purchase Order", label: "Purchase Order" },
								{ value: "None", label: "None" },
							]}
							value={formData.securityOrCollateral}
							onChange={handleSelectChange("securityOrCollateral")}
						/>
					</Box>
				</BaseCard>
			</ResponsiveGrid>

			<Box mt={2} display="flex" justifyContent="center" alignItems="center">
				{isLoading ? (
					<CircularProgress size="1rem" color="success" />
				) : (
					<CustomButton
						text="Proceed"
						onClick={handleProceed}
						variant="contained"
						color="success"
					/>
				)}
			</Box>
		</>
	);
};

export default FundingNeed3;
