import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import authService from "../../../common/services/auth-service";
import { User } from "../../../common/types";
import { setCookie, removeCookie } from "typescript-cookie";

interface AuthState {
	user: User | null;
	token: string | null;
	isAuthenticated: boolean;
	loading: boolean;
	error: string | null;
}

const initialState: AuthState = {
	user: null,
	token: null,
	isAuthenticated: false,
	loading: false,
	error: null,
};

export const login = createAsyncThunk(
	"auth/login",
	async (
		{ email, password }: { email: string; password: string },
		{ rejectWithValue }
	) => {
		try {
			const response = await authService.login(email, password);

			if (response.status) {
				throw new Error(response?.response?.data?.error || "Login failed.");
			}

			if (response?.token) {
				setCookie("accessToken", response.token);
				setCookie("refreshToken", response.refreshToken);
				setCookie("isAdmin", JSON.stringify(response?.user?.isAdmin ?? false));
				return {
					user: response.user,
					token: response.token,
				};
			}

			throw new Error("Unexpected response format.");
		} catch (error: any) {
			return rejectWithValue(error.message || "An unexpected error occurred.");
		}
	}
);

export const logout = createAsyncThunk("auth/logout", async () => {
	removeCookie("accessToken");
	removeCookie("refreshToken");
	removeCookie("isAdmin");
	return null;
});

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		resetError(state) {
			state.error = null;
		},
		setAuth(state, action: PayloadAction<{ user: User; token: string }>) {
			state.user = action.payload.user;
			state.token = action.payload.token;
			state.isAuthenticated = true;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(login.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(
				login.fulfilled,
				(
					state,
					action: PayloadAction<{ user: User; token: string } | undefined>
				) => {
					state.loading = false;
					if (action.payload) {
						state.user = action.payload.user;
						state.token = action.payload.token;
						state.isAuthenticated = true;
					} else {
						state.error = "Login failed. No user data returned.";
						state.isAuthenticated = false;
					}
				}
			)
			.addCase(login.rejected, (state, action) => {
				state.loading = false;
				state.error = (action.payload as string) || "Login failed";
				state.isAuthenticated = false;
			})
			.addCase(logout.fulfilled, (state) => {
				state.user = null;
				state.token = null;
				state.isAuthenticated = false;
			});
	},
});

export const { resetError, setAuth } = authSlice.actions;
export const authReducer = authSlice.reducer;
