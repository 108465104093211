import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DateState {
	startDate: string | null;
	endDate: string | null;
}

const initialState: DateState = {
	startDate: null,
	endDate: null,
};

const dateSlice = createSlice({
	name: "date",
	initialState,
	reducers: {
		setStartDate: (state, action: PayloadAction<string | null>) => {
			state.startDate = action.payload;
		},
		setEndDate: (state, action: PayloadAction<string | null>) => {
			state.endDate = action.payload;
		},
	},
});

export const { setStartDate, setEndDate } = dateSlice.actions;
export const dateReducer = dateSlice.reducer;
