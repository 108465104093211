import React, { useState } from "react";
import {
	CreditScore,
	Add,
	Person,
	AccountBalance,
	Description,
	BarChart,
	Delete,
	Edit,
	Money,
	HandshakeOutlined,
	Send,
	Upload,
	Visibility,
} from "@mui/icons-material";

import FloatingActionMenu, { Action } from "../Elements/Fab";
import LinkBankAccountModal from "../Modals/LinkBankAccountModal";
import FinancialDataModal from "../Modals/FinancialDataModal";

import moment from "moment";
import useUserBankData from "../../helpers/hooks/useUserBankData";
import {
	useClientDealChecklists,
	useDealChecklists,
} from "../../helpers/hooks/useClientService";
import ClientDealChecklistModal from "../Modals/ClientDealChecklistModal";
import CreditReportModal from "../Modals/CreditReportModal";
import UpdateProfileModal from "../Modals/UpdateProfileModal";
import FundingNeedModal from "../Modals/FundingNeedModal";
import ConfirmationModal from "../Modals/ConfirmationModal";
import toast from "react-hot-toast";
import {
	deleteContracts,
	deleteOffers,
	sendLoanContract,
	sendLoanOffer,
	softDeleteDeals,
} from "../../services/retool-service";
import DealChecklistModal from "../Modals/DealChecklistModal";
import UpdateDealStatusModal from "../Modals/UpdateDealStatus";
import LoanOfferModal from "../Modals/LoanOfferModal";
import CreateContractModal from "../Modals/CreateContractModal";
import UpdateOfferStatusModal from "../Modals/UpdateOfferStatus";
import LoanContractUploadContract from "../Modals/LoanContractUploadModal";
import { useQueryClient } from "react-query";
import SendConfirmationModal from "../Modals/SendConfirmationModal";

interface FabProps {
	data?: any;
	selectedRows: any[];
	refetch: () => void;
}

const ContractsFloatingActionMenu: React.FC<FabProps> = ({
	data,
	selectedRows,
	refetch,
}) => {
	const queryClient = useQueryClient();
	const userId = data?.userId;
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [isApproved, setIsApproved] = useState(false);

	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const [openAddContractModal, setOpenAddContractModal] = useState(false);
	const [openUploadDialog, setOpenUploadDialog] = useState(false);
	const [openUpdateOfferStatusModal, setOpenUpdateOfferStatusModal] =
		useState(false);
	const [openSendContractStatusModal, setOpenSendContractStatusModal] =
		useState(false);
	const [openSendContractStatusModal2, setOpenSendContractStatusModal2] =
		useState(false);

	const handleSendContractConfirmation = () => {
		if (data?.loanStatus == "Approved") {
			setOpenSendContractStatusModal(true);
			setConfirmationMessage(
				`This action will send the contract to this email: ${data?.email}. Are you sure you want to continue?`
			);
		} else {
			setOpenSendContractStatusModal2(true);
			// Temporarily removed
			// setConfirmationMessage(
			// 	`You can not proceed with this contract: ${data?.email} until the deal is approved.`
			// );
			setConfirmationMessage(
				`This action will send the contract to this email: ${data?.email}. Are you sure you want to continue?`
			);
		}
	};

	const handleSendContract = async () => {
		try {
			setIsLoading(true);

			const response = await sendLoanContract(data.id, isApproved);
			if (response?.data?.success) toast.success(response?.data?.message);
			else toast.error(response?.data?.message);
			setIsLoading(false);
			setOpenSendContractStatusModal(false);
			setOpenSendContractStatusModal2(false);
			refetch();
		} catch (error) {
			toast.error("Unable to send loan contract");
			setIsLoading(false);
			setOpenAddContractModal(false);
		}
	};

	const handleDelete = () => {
		setOpenConfirmationModal(true);

		const emails = selectedRows.map((row) => row.email).join(", ");

		setConfirmationMessage(
			`Are you sure you want to delete these contracts: ${emails}?`
		);
	};

	const handleDeleteContracts = async () => {
		try {
			setIsLoading(true);
			const payload = {
				Ids: selectedRows.map((row) => row.id),
			};

			const response = await deleteContracts(payload);
			if (response.success) {
				toast.success(response.message);
				queryClient.invalidateQueries("loanOffers");
				queryClient.invalidateQueries("loanContracts");
			} else toast.error(response.message);
			setIsLoading(false);
			setOpenConfirmationModal(false);
		} catch (error) {
			toast.error("Unable to delete contracts.");
			setIsLoading(false);
		}
	};

	const actions: Action[] = [
		{
			label: "Delete Contracts",
			icon: <Delete />,
			onClick: () => {
				handleDelete();
			},
		},
		{
			label: "Upload Contract",
			icon: <Upload />,
			onClick: () => {
				setOpenUploadDialog(true);
			},
		},
		{
			label: "Send Contract",
			icon: <Send />,
			onClick: () => {
				handleSendContractConfirmation();
			},
		},
		{
			label: "View Contract",
			icon: <Visibility />,
			onClick: () => {
				setOpenAddContractModal(true);
			},
		},
	];

	return (
		<>
			<LoanContractUploadContract
				open={openUploadDialog}
				onClose={() => setOpenUploadDialog(false)}
				contract={data}
				refetch={() => refetch()}
			/>
			<CreateContractModal
				open={openAddContractModal}
				onClose={() => setOpenAddContractModal(false)}
				contractData={data}
				type="contract"
			/>

			<FloatingActionMenu actions={actions} />
			<ConfirmationModal
				open={openSendContractStatusModal}
				onClose={() => setOpenSendContractStatusModal(false)}
				handleConfirmAction={handleSendContract}
				message={confirmationMessage}
				isLoading={isLoading}
			/>

			<ConfirmationModal
				open={openConfirmationModal}
				onClose={() => setOpenConfirmationModal(false)}
				handleConfirmAction={handleDeleteContracts}
				message={confirmationMessage}
				isLoading={isLoading}
			/>
			<SendConfirmationModal
				open={openSendContractStatusModal2}
				onClose={() => setOpenSendContractStatusModal2(false)}
				onConfirm={handleSendContract}
				message={confirmationMessage}
				isLoading={isLoading}
				isApproved={isApproved}
				setIsApproved={setIsApproved}
			/>
		</>
	);
};

export default ContractsFloatingActionMenu;
