import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import GreetingMessage from "../../common/components/Headings/GreetingMessage";
import BaseCard from "../../common/components/Cards/BaseCard";
import {
	Add,
	Delete,
	Download,
	Edit,
	Handshake,
	Link,
	LockClock,
	Paid,
	People,
	QuestionMark,
	Visibility,
} from "@mui/icons-material";
import CustomTable from "../../common/components/Tables/CustomTable";
import { CircularProgress, Tooltip, useTheme } from "@mui/material";
import CardWithIcon from "../../common/components/Elements/CardWithIcon";
import {
	useFetchLoanOffersSummary,
	useFetchApprovedLoanOffers,
	useFetchLoanOffers,
	useFetchLoanContracts,
} from "../../common/helpers/hooks/useLoanOfferService";
import CustomSearchBar from "../../common/components/Elements/CustomSearchBar";
import CustomTabs from "../../common/components/Elements/CustomTabs";
import DealsFloatingActionMenu from "../../common/components/Menus/DealsFloatingActionMenu";

import ConfirmationModal from "../../common/components/Modals/ConfirmationModal";
import { deleteLoanOffer } from "../../common/services/retool-service";
import toast from "react-hot-toast";
import ResponsiveGrid from "../../common/components/Grids/ResponsiveGrid";
import OffersFloatingActionMenu from "../../common/components/Menus/OffersFloatingActionMenu";
import LoadingIconButton from "../../common/components/Elements/LoadingIconButton";
import LoanOfferModal from "../../common/components/Modals/LoanOfferModal";

import UpdateContractStatus from "../../common/components/Modals/UpdateContractStatus";
import PdfViewerModal from "../../common/components/Modals/PdfViewModal";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import ContractsTable from "../../common/components/Tables/ContractsTable";
import DealsTable from "../../common/components/Tables/DealsTable";
import OffersTable from "../../common/components/Tables/OffersTable";
import { useLocation } from "react-router-dom";

const ManageOffersPage: React.FC = () => {
	const [openCreateOfferModal, setOpenCreateOfferModal] = useState(false);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [tabIndex, setTabIndex] = useState(0);

	const [page, setPage] = useState(1);
	const [size, setSize] = useState(10);
	const [selectedOffer, setSelectedOffer] = useState<any>(null);
	const [selectedContract, setSelectedContract] = useState<any>(null);
	const [selectedDeal, setSelectedDeal] = useState<any>(null);
	const [selectedOffers, setSelectedOffers] = useState<any[]>([]);
	const [selectedContracts, setSelectedContracts] = useState<any[]>([]);
	const [selectedDeals, setSelectedDeals] = useState<any[]>([]);
	const [openUpdateContractModal, setOpenUpdateContractModal] = useState(false);

	const plugin = defaultLayoutPlugin();
	const [openPdfDialog, setOpenPdfDialog] = useState(false);
	const [pdfUrl, setPdfUrl] = useState("");
	const [search, setSearch] = useState("");
	const [searchTerm, setSearchTerm] = useState("");
	const [offersSearchTerm, setOffersSearchTerm] = useState("");
	const [contractsSearchTerm, setContractsSearchTerm] = useState("");
	const theme = useTheme();
	const [filteredOffersData, setFilteredOffersData] = useState([]);
	const [filteredContractsData, setFilteredContractsData] = useState([]);
	const [filteredDealsData, setFilteredDealsData] = useState([]);

	const tabs = [
		{ label: "Deals" },
		{ label: "Offers" },
		{ label: "Contracts" },
	];

	const handleFetchNewPage = async (newPage: number) => {
		setPage(newPage);
	};

	const handleSize = async (newSize: number) => {
		setSize(newSize);
	};

	const handleSubmitSearch = () => {
		if (tabIndex == 0) setSearch(searchTerm);
		else if (tabIndex == 1) setSearch(offersSearchTerm);
		else if (tabIndex == 2) setSearch(contractsSearchTerm);
	};

	const {
		data: offers,
		refetch,
		isLoading: isLoadingOffers,
		isRefetching: isOffersFetching,
	} = useFetchLoanOffers({ page, size, searchTerm: search });
	const {
		data: deals,
		refetch: refetchDeals,
		isRefetching: isDealsFetching,
		isLoading: isDealsLoading,
	} = useFetchApprovedLoanOffers({ page, size, searchTerm: search });
	const {
		data: contracts,
		refetch: refetchContracts,
		isRefetching: isContractFetching,
		isLoading: isContractsLoading,
	} = useFetchLoanContracts({ page, size, searchTerm: search });

	const {
		data: summary,
		isRefetching: isRefetchingSummary,
		refetch: refetchSummary,
		isLoading: isLoadingSummary,
	} = useFetchLoanOffersSummary();

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const handleOffersSearchChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setOffersSearchTerm(event.target.value);
	};

	const handleContractsSearchChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setContractsSearchTerm(event.target.value);
	};

	useEffect(() => {
		const dealsData = offers?.data?.data;
		if (dealsData) {
			setFilteredOffersData(dealsData);
			setSelectedOffers([]);
			setSelectedOffer(null);
		}
	}, [offers]);

	useEffect(() => {
		const contractData = contracts?.data?.data;
		if (contractData) {
			setFilteredContractsData(contractData);
			setSelectedContracts([]);
			setSelectedContract(null);
		}
	}, [contracts]);

	const handleDelete = (row: any) => {
		setSelectedOffer(row);
		setOpenConfirmationModal(true);
		setConfirmationMessage(
			`Are you sure you want to delete ${row?.email} 's loan offer of R${row?.approvedFundingAmount}`
		);
	};

	const handleDeleteOffer = async () => {
		if (selectedOffer) {
			try {
				setIsLoading(true);
				const response = await deleteLoanOffer(selectedOffer.id);
				if (response.success) {
					toast.success(response.message);
				} else {
					toast.error(response.message);
				}
				setIsLoading(false);
				setOpenConfirmationModal(false);
				refetch();
			} catch (error) {
				toast.error("Unable to delete loan offer.");
				setIsLoading(false);
			}
		}
	};

	useEffect(() => {
		const dealsData = deals?.data?.data;

		if (dealsData) {
			setFilteredDealsData(dealsData);
			setSelectedDeals([]);
			setSelectedDeal(null);
		}
	}, [deals]);

	const location = useLocation();

	useEffect(() => {
		refetch();
		refetchContracts();
		refetchDeals();
		refetchSummary();
	}, [location.pathname]);

	return (
		<Box
			sx={{
				py: 4,
				px: 3,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				textAlign: "center",
				width: "100%",
				gap: 2,
			}}
		>
			<ResponsiveGrid columnCount={4}>
				<CardWithIcon
					text="Pending Offers"
					icon={
						<LockClock
							style={{ color: theme.palette.text.primary, fontSize: "34px" }}
						/>
					}
					label={summary?.data?.data?.totalPendingOffers}
					sx={{ cursor: "auto" }}
				/>
				<CardWithIcon
					text="Offer Request"
					icon={
						<QuestionMark
							style={{ color: theme.palette.text.primary, fontSize: "34px" }}
						/>
					}
					label={summary?.data?.data?.totalOfferRequests}
					sx={{ cursor: "auto" }}
				/>
				<CardWithIcon
					text="Accepted Offers"
					icon={
						<Handshake
							style={{ color: theme.palette.text.primary, fontSize: "34px" }}
						/>
					}
					label={summary?.data?.data?.totalAcceptedOffers}
					sx={{ cursor: "auto" }}
				/>
			</ResponsiveGrid>

			<BaseCard sx={{ height: "auto" }}>
				<Box px={2} py={2}>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
					>
						<GreetingMessage title="Offers & Contracts" />
						<LoadingIconButton
							onClick={() => {
								refetch();
								refetchContracts();
								refetchDeals();
								refetchSummary();
							}}
							isLoading={
								isOffersFetching ||
								isContractFetching ||
								isDealsFetching ||
								isRefetchingSummary
							}
						/>
					</Box>
					<Box
						width="100%"
						my={2}
						gap={2}
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Box sx={{ flexGrow: 1 }}>
							<CustomTabs
								tabs={tabs}
								selectedTab={tabIndex}
								onTabChange={(event, newValue) => {
									setTabIndex(newValue);
									setPage(0);
								}}
							/>
						</Box>
					</Box>

					{tabIndex === 1 && (
						<OffersTable
							filteredOffersData={filteredOffersData}
							isLoadingOffers={isLoadingOffers}
							offersSearchTerm={offersSearchTerm}
							handleOffersSearchChange={handleOffersSearchChange}
							setSelectedRow={setSelectedOffer}
							setOpenCreateOfferModal={setOpenCreateOfferModal}
							handleDelete={handleDelete}
							setSelectedRows={setSelectedOffers}
							selectedRows={selectedOffers}
							totalItems={offers?.data?.totalItems}
							page={page}
							handleFetchNewPage={handleFetchNewPage}
							isRefetching={isOffersFetching}
							handleSize={handleSize}
							handleSearch={handleSubmitSearch}
						/>
					)}
					{tabIndex === 2 && (
						<ContractsTable
							selectedRow={selectedContract}
							filteredContractsData={filteredContractsData}
							isContractsLoading={isContractsLoading}
							contractsSearchTerm={contractsSearchTerm}
							handleContractsSearchChange={handleContractsSearchChange}
							setSelectedRow={setSelectedContract}
							setOpenUpdateContractModal={setOpenUpdateContractModal}
							setPdfUrl={setPdfUrl}
							setOpenPdfDialog={setOpenPdfDialog}
							refetch={refetchContracts}
							selectedContracts={selectedContracts}
							setSelectedContracts={setSelectedContracts}
							totalItems={contracts?.data?.totalItems}
							page={page}
							handleFetchNewPage={handleFetchNewPage}
							isRefetching={isContractFetching}
							handleSize={handleSize}
							handleSearch={handleSubmitSearch}
						/>
					)}
					{tabIndex === 0 && (
						<DealsTable
							filteredDealsData={filteredDealsData}
							isDealsFetching={isDealsLoading}
							searchTerm={searchTerm}
							handleSearchChange={handleSearchChange}
							setSelectedRow={setSelectedDeal}
							setOpenCreateOfferModal={setOpenCreateOfferModal}
							selectedDeals={selectedDeals}
							totalItems={deals?.data?.totalItems}
							page={page}
							handleFetchNewPage={handleFetchNewPage}
							isRefetching={isDealsFetching}
							handleSize={handleSize}
							handleSearch={handleSubmitSearch}
						/>
					)}
				</Box>
				{selectedOffers.length > 0 && tabIndex == 1 && (
					<OffersFloatingActionMenu
						selectedRows={selectedOffers}
						data={
							selectedOffers[selectedOffers ? selectedOffers.length - 1 : 0]
						}
						setTabIndex={setTabIndex}
					/>
				)}
			</BaseCard>

			<LoanOfferModal
				open={openCreateOfferModal}
				onClose={() => setOpenCreateOfferModal(false)}
				selectedApplication={tabIndex == 1 ? selectedOffer : selectedDeal}
				client={tabIndex == 1 ? selectedOffer : selectedDeal}
				type={tabIndex == 1 ? "offer" : "deal"}
				setTabIndex={setTabIndex}
			/>

			<ConfirmationModal
				open={openConfirmationModal}
				onClose={() => setOpenConfirmationModal(false)}
				handleConfirmAction={handleDeleteOffer}
				message={confirmationMessage}
				isLoading={isLoading}
			/>
			<UpdateContractStatus
				open={openUpdateContractModal}
				handleClose={() => setOpenUpdateContractModal(false)}
				selectedRow={selectedContract}
			/>
			<PdfViewerModal
				open={openPdfDialog}
				handleClose={() => setOpenPdfDialog(false)}
				pdfUrl={pdfUrl}
				plugin={plugin}
			/>
		</Box>
	);
};

export default ManageOffersPage;
