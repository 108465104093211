import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
	retrieveTransactionSummary,
	getMonthlyCapitalAdvances,
	getRevenuesCollected,
	getTotalMonthlyLoans,
	getTotalMonthlyLoansPerClient,
	getLoanBookByCounterPartyValue,
	getClientSectorDistribution,
	getClientGenderDistribution,
	getCumulativeClientsPerMonth,
	getCumulativeCapitalAdvances,
} from "../../services/finance-service";
import { RootState } from "../../../app/store";

const useFetchQuery = (key: string, fetchFn: Function) => {
	const { startDate, endDate } = useSelector((state: RootState) => state.date);

	const enabled = !!(startDate && endDate) || (!startDate && !endDate);

	return useQuery(
		[key, startDate, endDate],
		() => fetchFn(startDate, endDate),
		{
			enabled,
		}
	);
};

export const useTransactionSummary = () =>
	useFetchQuery("transactionSummary", retrieveTransactionSummary);

export const useMonthlyCapitalAdvances = () =>
	useFetchQuery("monthlyCapitalAdvances", getMonthlyCapitalAdvances);

export const useRevenuesCollected = () =>
	useFetchQuery("revenuesCollected", getRevenuesCollected);

export const useTotalMonthlyLoans = () =>
	useFetchQuery("totalMonthlyLoans", getTotalMonthlyLoans);

export const useTotalMonthlyLoansPerClient = () =>
	useFetchQuery("totalMonthlyLoansPerClient", getTotalMonthlyLoansPerClient);

export const useLoanBookByCounterPartyValue = () =>
	useFetchQuery("loanBookByCounterPartyValue", getLoanBookByCounterPartyValue);

export const useClientSectorDistribution = () =>
	useFetchQuery("clientSectorDistribution", getClientSectorDistribution);

export const useClientGenderDistribution = () =>
	useFetchQuery("clientGenderDistribution", getClientGenderDistribution);

export const useCumulativeClientsPerMonth = () =>
	useFetchQuery("cumulativeClientsPerMonth", getCumulativeClientsPerMonth);

export const useCumulativeCapitalAdvances = () =>
	useFetchQuery("cumulativeCapitalAdvances", getCumulativeCapitalAdvances);
