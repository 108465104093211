import { getAllApplicationTasks, getBackgroundJobs, getDealOverview, getLendersSmeChartData, getLendersSmeStatistics, getLendersTasks, getMonthlyTransactionsSummary, getSmeDeals } from '../../../common/services/retool-service';

import { 
  chartSummaryCardLoanPerformanceProps, 
  clientListProps, 
  debtServiceCoverRatioRatingCardProps, 
  flaggedTransactionsTableProps, 
  greetingMessageProps, 
  incomeAndfinanceCardProps, 
  infoRiskCardProps, 
  lineChartSummaryCardRepaymentHistoryProps, 
  listCardPropsActivityLog, 
  listCardPropsTaskList, 
  pinnedClientTableProps, 
  portfolioBusinessSizeDoughnutSummaryCardProps, 
  profileRiskStatusProps, 
  provincialPresenceColoredBoxSummaryCard2Props, 
  riskHistoryProps, 
  summaryCardClientsProps, 
  summaryCardHistoryProps, 
  taskCardProps, 
  topIndustriesColoredBoxSummaryCardProps 
} from '../../../data/dataSource';

import { useQuery } from 'react-query';
import { getTopNPercentages } from '../utils/arrayUtils';
import { getAlerts } from '../../../common/services/post-investment-service';
import { useCurrentUser, useUserId } from './useUserService';
import moment from 'moment';

const useCustomQuery = (key: any, fetchData:any, initialData: any) => {
 
  const { data, error, isLoading, refetch } = useQuery(key, fetchData, {
    
    refetchOnMount: true,
    refetchOnWindowFocus: true, 
    enabled: true,
    onError: (error) => {
      console.error('Error fetching data:', error);
      // Handle error state if needed
    },
  });

  return {
    data, 
    error,
    isLoading,
    refetch,
  };
};

export const useGreetingMessage = () => {
  const currentUser = useCurrentUser();
  const fetchData = async () => {
    const username = currentUser?.name ?? 'Guest';
    const greetingMessageProps = { title: username }; 
    return greetingMessageProps;
  };

  return useCustomQuery('greetingMessage', fetchData, greetingMessageProps);
};

export const useInfoRiskCard = () => {  
  const fetchData = async () => {
    const response = await getAlerts();
    const alerts = response?.data?.data || [];
    const descriptions =  alerts?.map((x: any) => x.description);
    const infoRiskCard = { ...infoRiskCardProps };
    infoRiskCard.additionalLabels = descriptions;
    infoRiskCard.labelText = `+${Math.max(descriptions.length - 3, 0)} more`;
    infoRiskCard.circleInnerText = descriptions?.length;
  
    return infoRiskCard;
  };
  
  return useCustomQuery('infoRiskCard', fetchData, infoRiskCardProps);
};

export const useActivityLog = () => {
  const fetchData = async () => {
    const response = await getBackgroundJobs();
    const jobs = response?.data?.data || [];
    
    const activityLog = {...listCardPropsActivityLog};
    if(jobs.length > 0){
      activityLog.logs.Activity = [
        {text: jobs[0]?.description, alternativeText: jobs[0]?.email },
        {text: jobs[1]?.description, alternativeText: jobs[1]?.email },
        {text: jobs[2]?.description, alternativeText: jobs[2]?.email },
      ]
    }else{
      activityLog.logs.Activity = [];
    }
    
    return activityLog;
  };

  return useCustomQuery('activityLog', fetchData, listCardPropsActivityLog);
};

export const useClientsSummaryCard = () => {

  const fetchData = async () => {

    const response = await getDealOverview();
    const data = response?.data?.data;

    const clientsSummary = { ...summaryCardClientsProps };
    clientsSummary.title = "Deal Overview"
    clientsSummary.leftPrimaryHeading = `${data?.noOfApprovedDeals} active clients`;
    clientsSummary.rightPrimaryHeading = `${data?.noOfPendingApprovalDeals} Deals pending approval`;
    clientsSummary.pillInnerText = `+${data?.approvedInLast30Days}`;
    clientsSummary.additionalRightLabels = data?.pendingApprovalDeals;

    return clientsSummary;
  };

  return useCustomQuery('clientsSummary', fetchData, {...summaryCardClientsProps, leftPrimaryHeading: '0 active client', rightPrimaryHeading: '0 High-risk clients'});
};

export const useHistorySummaryCard = () => {
  const currentUser = useCurrentUser();
  const fetchData = async () => {

    const startDate = moment().subtract(12, 'months').format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');
    const response = await getLendersSmeStatistics(startDate, endDate);

    const stats = response.data;
    
    const historySummary  = {...summaryCardHistoryProps};
    historySummary.leftPrimaryHeading = `${stats.totalSMEFunded.total} Loans disbursed`;
    historySummary.rightPrimaryHeading = ` R ${stats.totalFundingDisbursed?.total?.toLocaleString()} loaned`;
    historySummary.additionalLeftLabels = [`${stats.totalSMEFunded.total} Currently active`];
    historySummary.additionalRightLabels = [`R ${stats?.totalFundingDisbursed?.total?.toLocaleString()} currently loaned`];
    historySummary.leftSecondaryText = `Since ${moment().subtract(12, 'months').format('YYYY-MM-DD')}`;
    historySummary.rightSecondaryText = `Since ${moment().subtract(12, 'months').format('YYYY-MM-DD')}`;

    return historySummary;
  };

  return useCustomQuery('historySummary', fetchData, {...summaryCardHistoryProps});
};

export const useLoanPerformanceChart = () => {
  const fetchData = async () => {
    // Simulate fetching your data here
    throw new Error('Fetch error');
  };

  return useCustomQuery('loanPerformanceChart', fetchData, chartSummaryCardLoanPerformanceProps);
};

export const useTaskList = () => {

  const fetchData = async () => {
    const response = await getLendersTasks();
    const tasks = response.data?.data;
  
    const taskList = { ...listCardPropsTaskList };
  
    taskList.logs.Todo = tasks
      .filter((x: any) => x.status === 'Todo')
      .map((x: any) => ({
        ...x,
        text: x.name,
        checked: false
      }));
  
    taskList.logs['In-Progress'] = tasks
      .filter((x: any) => x.status === 'In-Progress')
      .map((x: any) => ({
        ...x,
        text: x.name,
        checked: false
      }));
  
    taskList.logs.Done = tasks
      .filter((x: any) => x.status === 'Done')
      .map((x: any) => ({
        ...x,
        text: x.name,
        checked: true
      }));
  
    return taskList;
  };
  

  return useCustomQuery('taskList', fetchData, listCardPropsTaskList);
};

export const useTask = () => {

  const fetchData = async () => {
    const response = await getAllApplicationTasks();
    const tasks = response.data?.data;
  
    const taskCard = { ...taskCardProps };
    const statusTypes = ['Todo', 'InProgress', 'Completed'];
  
    taskCard.tasks = statusTypes.map(status => {
      const filteredTasks = tasks.filter((task:any) => task.status === status);
      const lowPriorityCount = filteredTasks.filter((task:any) => task?.priority == "Low").length;
      const highPriorityCount = filteredTasks.filter((task:any) => task?.priority == "High").length;
      return {
        status: status,
        taskCount: filteredTasks.length, 
        highPriorityCount: highPriorityCount,
        lowPriorityCount: lowPriorityCount,
        dueTodayCount: 0 
      };
    });
  
    taskCard.title = "Tasks";
    return taskCard;
  };

  return useCustomQuery('tasks', fetchData, taskCardProps);
};

export const useRepaymentHistory = () => {
  const fetchData = async () => {
    // Simulate fetching your data here
    throw new Error('Fetch error');
  };

  return useCustomQuery('repaymentHistory', fetchData, lineChartSummaryCardRepaymentHistoryProps);
};

export const useIncomeAndFinanceCard = () => {
  const userId = useUserId();
  const fetchData = async () => {
    
    const response = await getMonthlyTransactionsSummary(userId);
  
    const incomeAndFinanceCard = { ...incomeAndfinanceCardProps };
    incomeAndFinanceCard.income = response.data?.monthlyRevenue ?? 0; 
    incomeAndFinanceCard.expense = response.data?.totalExpenses ?? 0; 
    incomeAndFinanceCard.total = (incomeAndFinanceCard.income - incomeAndFinanceCard.expense) / 2; 
  
    return incomeAndFinanceCard;
  };

  return useCustomQuery('incomeAndFinanceCard', fetchData, incomeAndfinanceCardProps);
};

export const useDebtServiceCoverRatioRating = () => {
  const fetchData = async () => {
    // Simulate fetching your data here
    throw new Error('Fetch error');
  };

  return useCustomQuery('debtServiceCoverRatioRating', fetchData, debtServiceCoverRatioRatingCardProps);
};

export const usePortfolioBusinessSizeDoughnutSummary = () => {
  const fetchData = async () => {
    // Simulate fetching your data here
    throw new Error('Fetch error');
  };

  return useCustomQuery('portfolioBusinessSizeDoughnutSummary', fetchData, portfolioBusinessSizeDoughnutSummaryCardProps);
};

export const useTopIndustries = () => {
  
  const fetchData = async () => {

    const response = await getLendersSmeChartData();
    const { industryLabels, industryData } = response.data;
  
    const { topN, remaining } = getTopNPercentages(industryData, 3);
  
    const topIndustries = { ...topIndustriesColoredBoxSummaryCardProps };
  
    topN.forEach((item, index) => {
      topIndustries.segments[index].name = industryLabels[item.index];
      topIndustries.segments[index].value = item.percentage;
    });
  
    topIndustries.segments[3].name = 'Others';
    topIndustries.segments[3].value = remaining;
  
    topIndustries.others = industryLabels.filter((_:any, i:number) => !topN.map(item => item.index).includes(i));
  
    return topIndustries;
  };
  
  return useCustomQuery('topIndustries', fetchData, topIndustriesColoredBoxSummaryCardProps);
};  

export const useProvincialPresence = () => {

  const fetchData = async () => {

    const response = await getLendersSmeChartData();
  
    const { geographyLabels, geographyData } = response.data;

    const provincialPresenceData = {
      title: provincialPresenceColoredBoxSummaryCard2Props.title,
      segments: geographyLabels.map((each: any, index: number) => ({
        name: each,
        value: geographyData[index],
      })),
    };

    return provincialPresenceData;
  };

  const response = useCustomQuery('provincialPresence', fetchData, {title: provincialPresenceColoredBoxSummaryCard2Props.title, segments: []});

  return response;
};


export const useClientList = () => {
  const fetchData = async () => {
    // Simulate fetching your data here
    throw new Error('Fetch error');
  };

  return useCustomQuery('clientList', fetchData, clientListProps);
};

export const useRiskHistory = () => {
  const fetchData = async () => {
    // Simulate fetching your data here
    throw new Error('Fetch error');
  };

  return useCustomQuery('riskHistory', fetchData, riskHistoryProps);
};

export const useProfileRiskStatus = () => {
  const fetchData = async () => {
    // Simulate fetching your data here
    throw new Error('Fetch error');
  };

  return useCustomQuery('profileRiskStatus', fetchData, profileRiskStatusProps);
};


export const usePinnedClientTable = () => {
  
  const fetchData = async () => {

    const response = await getSmeDeals();
    
    let watchlistedSmes = response.data.filter((x:any) => x.isWatchlisted);

    watchlistedSmes = watchlistedSmes.map((x:any) => {
      return {
        company: x.companyName,
        contact: `${x.firstname} ${x.lastname}`,
        activeSince: x.createdTimestamp,
        borrowed: `R ${x.fundingAmount.toLocaleString()}`,
        tasks: x.actions,
        remainingTerm: `${x.remainingTerm ? x.remainingTerm : 0} months`,
        riskStatus: x.riskStatus,
        flags: [x.riskStatus]
      }
    });

    const pinnedClients = { ...pinnedClientTableProps };

    pinnedClients.data = watchlistedSmes;
    
    return pinnedClients;
  };

  return useCustomQuery('pinnedClientTable', fetchData, {...pinnedClientTableProps, data: [] });
};

export const useFlaggedTransactionsTable = () => {
  const fetchData = async () => {
    // Simulate fetching your data here
    throw new Error('Fetch error');
  };

  return useCustomQuery('flaggedTransactionsTable', fetchData, flaggedTransactionsTableProps);
};
