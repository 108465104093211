import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { Modal, Stepper, Step, StepLabel } from "@mui/material";
import { AddCircle, ArrowBack } from "@mui/icons-material";
import GreetingMessage from "../Headings/GreetingMessage";
import FundingNeed from "../Forms/FundingNeed";

import { useTheme } from "@mui/material";
import CompleteProfile from "../Forms/CompleteProfile";
import DealChecklist from "../Forms/Dealchecklist";
import DealSummary from "../Forms/DealSummary";
import CustomStepper from "../Elements/CustomStepper";
import CustomTable from "../Tables/CustomTable";
import { useFetchClients } from "../../../common/helpers/hooks/useClientService";
import CustomSearchBar from "../Elements/CustomSearchBar";
import AddClientModal2 from "./AddClientModal2";
import CardWithIcon from "../Elements/CardWithIcon";
import FundingNeed2 from "../Forms/FundingNeed2";
import FundingNeed3 from "../Forms/FundingNeed3";
import CustomButton from "../Elements/CustomButton";
import CustomFab from "../Elements/CustomFab";

interface ReviewDealModalProps {
	open: boolean;
	onClose: () => void;
	deal: any;
}

const steps = ["Select Client", "Create Deal", "Review Deal"];

const CreateDealModal: React.FC<ReviewDealModalProps> = ({
	open,
	onClose,
	deal,
}) => {
	const theme = useTheme();
	const [selectedStep, setSelectedStep] = useState<number>(0);
	const [openAddClientModal, setOpenAddClientModal] = useState(false);
	const navigate = useNavigate();
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(10);
	const [search, setSearch] = useState("");
	const [selectedRow, setSelectedRow] = useState<any>(null);
	const [selectedRows, setSelectedRows] = useState<any[]>([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [formData, setFormData] = useState({
		fundingAmount: 5000,
		duration: 5,
		useOfFunds: [],
		securityOrCollateral: "None",
		productType: "",
	});

	const {
		data: clients,
		isLoading: isFetching,
		refetch: refetchClients,
		isRefetching,
	} = useFetchClients({ page, size, searchTerm: search });

	const handleFetchNewPage = async (newPage: number) => {
		setPage(newPage);
	};

	const handleSize = async (newSize: number) => {
		setSize(newSize);
	};

	const handleBack = () => {
		navigate("/dashboard");
	};

	const handleStepChange = (step: number) => {
		setSelectedStep(step);
	};

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};
	const handleSubmitSearch = () => {
		setSearch(searchTerm);
	};
	const refetch = () => {
		refetchClients();
	};

	const [filteredData, setFilteredData] = useState([]);

	useEffect(() => {
		if (clients?.data) {
			setFilteredData(clients?.data);
			setSelectedRows([]);
		}
	}, [clients]);

	return (
		<>
			<Modal open={open} onClose={onClose}>
				<Box
					sx={{
						py: 2,
						px: 3,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						textAlign: "center",
						width: "100%",
						height: "100%",
						backgroundColor: theme.palette.background.paper,
						gap: 2,
						overflow: "auto",
					}}
				>
					<Box
						sx={{ cursor: "pointer", width: "100%" }}
						onClick={onClose}
						display="flex"
						gap={1}
						alignItems="center"
						justifyContent="flex-start"
					>
						<ArrowBack sx={{ fill: "rgba(73, 69, 79, 1)" }} />
						<GreetingMessage title="Back" />
					</Box>
					<Box width="70%">
						<CustomStepper
							steps={steps}
							activeStep={selectedStep}
							setActiveStep={setSelectedStep}
						/>
					</Box>

					<Box sx={{ width: "100%", mt: 2 }}>
						{selectedStep === 0 && (
							<>
								<Box
									width="100%"
									my={2}
									gap={2}
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Box sx={{ flexGrow: 1 }}>
										<CustomSearchBar
											searchTerm={searchTerm}
											onSearchChange={handleSearchChange}
											handleSearch={handleSubmitSearch}
										/>
									</Box>
									<Box sx={{ marginLeft: "auto" }}>
										<CardWithIcon
											icon={
												<AddCircle
													style={{
														color:
															theme.palette.mode == "light"
																? "rgba(73, 69, 79, 1)"
																: "white",
														marginRight: "5px",
														fontSize: "18px",
													}}
												/>
											}
											text="Add Client"
											onClick={() => {
												setOpenAddClientModal(true);
											}}
										/>
									</Box>
								</Box>
								<CustomTable
									isMultiSelect={false}
									isLoading={isFetching || isRefetching}
									columns={[
										{ title: "Email", key: "email", sortable: true },
										{
											title: "KYC",
											key: "isUserOnboardingComplete",
											sortable: true,
											isPill: true,
										},
										{
											title: "KYB",
											key: "isBusinessOnboardingComplete",
											sortable: true,
											isPill: true,
										},
										{
											title: "Consumer Score",
											key: "consumerCreditScore",
											sortable: true,
											isDecimal: false,
										},
										{
											title: "Consumer Rating",
											key: "consumerCreditCategory",
											sortable: true,
											isPill: true,
										},
										{
											title: "Commercial Score",
											key: "commercialCreditScore",
											sortable: true,
										},
										{
											title: "Commercial Rating",
											key: "commercialCreditCategory",
											sortable: true,
											isPill: true,
										},
									]}
									data={filteredData ? filteredData : []}
									onRowSelect={(rows) => {
										setSelectedRows(rows);
										setSelectedRow(rows[rows ? rows.length - 1 : 0]);
									}}
									selectable
									selected={selectedRows}
									totalItems={clients?.totalItems || 0}
									handleNextPage={handleFetchNewPage}
									handleSize={handleSize}
								/>
							</>
						)}
						{selectedStep === 1 && (
							<FundingNeed3
								client={selectedRow}
								formData={formData}
								setFormData={setFormData}
								handleNext={
									handleStepChange ? () => handleStepChange(2) : undefined
								}
							/>
						)}
						{selectedStep === 2 && (
							<FundingNeed3
								client={selectedRow}
								formData={formData}
								setFormData={setFormData}
								isSubmit={true}
								handleNext={() => onClose()}
							/>
						)}
					</Box>
					{selectedRows.length == 1 && selectedStep == 0 && (
						<CustomFab action={() => setSelectedStep(1)} text="Proceed" />
					)}
				</Box>
			</Modal>

			<AddClientModal2
				refetch={() => refetch()}
				open={openAddClientModal}
				onClose={() => setOpenAddClientModal(false)}
			/>
		</>
	);
};

export default CreateDealModal;
