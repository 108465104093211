import React from "react";
import {
	ResponsiveContainer,
	PieChart,
	Pie,
	Tooltip,
	Cell,
	Legend,
} from "recharts";
import { Box, Alert, Typography, CircularProgress } from "@mui/material";
import { useClientSectorDistribution } from "../../../common/helpers/hooks/useFinanceService";
import BaseCard from "../Cards/BaseCard";
import NoDataFoundCard from "../Cards/NoDataFoundCard";

const ClientSectorDistributionChart = () => {
	const { data, isLoading, error } = useClientSectorDistribution();

	if (isLoading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height={300}
			>
				<CircularProgress color="success" />
			</Box>
		);
	}

	if (error) {
		return (
			<Alert severity="error">
				Failed to load data. Please try again later.
			</Alert>
		);
	}

	if (!data || !data.loans || data.loans.length === 0) {
		return <NoDataFoundCard title="Client Sector Distribution" />;
	}

	const chartData = data.loans.map((item: any) => ({
		sector: item.sector || "Unknown",
		percentage: item.sectorPercentage
			? parseFloat(item.sectorPercentage.replace("%", ""))
			: 0,
		percent: item.percentage,
		total: item.total || 0,
	}));

	const generateColor = (index: number) => {
		const baseR = 70;
		const baseG = 130;
		const baseB = 180;
		const r = (baseR + index * 30) % 255;
		const g = (baseG + index * 40) % 255;
		const b = (baseB + index * 50) % 255;

		const rgbToHex = (r: number, g: number, b: number) => {
			const toHex = (x: number) => x.toString(16).padStart(2, "0");
			return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
		};

		return rgbToHex(r, g, b);
	};

	return (
		<BaseCard sx={{ height: "auto", padding: "20px 10px" }}>
			<Typography variant="body2" gutterBottom>
				Client Sector Distribution
			</Typography>
			<ResponsiveContainer width="100%" height={400}>
				<PieChart>
					<Pie
						data={chartData}
						dataKey="total"
						nameKey="sector"
						cx="50%"
						cy="50%"
						outerRadius={120}
						innerRadius={80}
						label={({ percent, x, y, total }) => (
							<text
								x={x}
								y={y}
								textAnchor="middle"
								style={{
									fontSize: "10px",
									fill: "#333",
								}}
							>
								{percent}: {total}
							</text>
						)}
						labelLine={true}
					>
						{chartData.map((_: any, index: number) => (
							<Cell key={`cell-${index}`} fill={generateColor(index)} />
						))}
					</Pie>
					<Tooltip
						formatter={(value: number, name: string, props: any) =>
							`${props.payload.percent} | Total: ${props.payload.total}`
						}
					/>
					<Legend
						verticalAlign="bottom"
						wrapperStyle={{
							fontSize: "10px",
							padding: "10px",
						}}
						formatter={(value: any, entry: any) => (
							<span style={{ fontSize: "12px" }}>
								{`${value} - ${entry.payload.total} (${entry.payload.percent})`}
							</span>
						)}
					/>
				</PieChart>
			</ResponsiveContainer>
		</BaseCard>
	);
};

export default ClientSectorDistributionChart;
