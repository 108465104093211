import React, { useState } from "react";
import { Box, Typography, CircularProgress, Paper, Grid } from "@mui/material";
import CompleteClientDetailsModal from "../Modals/CompleteClientDetailsModal";

const AssessmentBox = ({ label, value, isPositive, info }) => (
	<Box
		sx={{
			p: 2,
			backgroundColor: isPositive ? "#e8f5e9" : "#ffebee",
			borderRadius: "8px",
			mb: 2,

			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
		}}
	>
		<Typography fontSize="14px">
			<strong>{label}</strong> {info}
		</Typography>
		<Box
			sx={{
				px: 2,
				py: 0.5,
				backgroundColor: isPositive ? "green" : "red",
				borderRadius: "16px",
				color: "white",
			}}
		>
			{isPositive ? "Yes" : "No"}
		</Box>
	</Box>
);

const UserAssessment = ({ userAssessment, isFetchingAssessment, deal }) => {
	const data = userAssessment?.data?.data || {};
	const [openViewDetailsModal, setOpenViewDetailsModal] = useState(false);

	return (
		<Box sx={{ width: "100%" }}>
			<Typography variant="h6" sx={{ mb: 2 }}>
				Application Flags
			</Typography>
			{isFetchingAssessment ? (
				<CircularProgress color="success" size={20} />
			) : (
				<Paper elevation={1} sx={{ p: 2 }}>
					<Grid container spacing={2}>
						<Grid
							item
							xs={12}
							md={6}
							sx={{ cursor: "pointer" }}
							onClick={() => setOpenViewDetailsModal(true)}
						>
							<AssessmentBox
								label={`Consumer Score > ${data.requiredConsumerScore}: `}
								info={`${data.consumerScore >= data.requiredConsumerScore ? "Credit score is within the accepted threshold" : "Credit score is  below the accepted threshold"}`}
								value={data.consumerScore}
								isPositive={data.consumerScore >= data.requiredConsumerScore}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							onClick={() => setOpenViewDetailsModal(true)}
							sx={{ cursor: "pointer" }}
						>
							<AssessmentBox
								label={`Commercial Score > ${data.requiredCommercialScore}: `}
								info={`${data.commercialScore >= data.requiredCommercialScore ? "Credit score is within the accepted threshold" : "Credit score is  below the accepted threshold"}`}
								value={data.commercialScore}
								isPositive={
									data.commercialScore >= data.requiredCommercialScore
								}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<AssessmentBox
								label="Financial data: "
								info={`${data.bankStatementUploaded ? "Financial data: Bank statements have been uploaded" : "Bank statements have not been uploaded"}`}
								value={data.bankStatementUploaded}
								isPositive={data.bankStatementUploaded}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<Box
								sx={{
									p: 2,
									backgroundColor:
										data.directorCount > 0 &&
										data.directorCount <= data.requiredDirectorCount
											? "#e8f5e9"
											: "#ffebee",
									borderRadius: "8px",
									mb: 2,
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<Typography fontSize="14px">
									<strong>Linked Companies: </strong>{" "}
									{data.directorCount > 0 &&
									data.directorCount <= data.requiredDirectorCount
										? "Client is listed as a director within the accepted limit of companies"
										: "Client is listed as a director above the accepted limit of companies"}
								</Typography>
								<Box
									sx={{
										px: 2,
										py: 0.5,
										backgroundColor:
											data.directorCount > 0 &&
											data.directorCount <= data.requiredDirectorCount
												? "green"
												: "red",
										borderRadius: "16px",
										color: "white",
									}}
								>
									{data.directorCount || "0"}
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Paper>
			)}
			<CompleteClientDetailsModal
				open={openViewDetailsModal}
				onClose={() => setOpenViewDetailsModal(false)}
				selectedApplication={deal}
				tab={1}
			/>
		</Box>
	);
};

export default UserAssessment;
