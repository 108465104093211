import React, { useState } from "react";
import { Box, Tooltip, useTheme, Fab } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import Visibility from "@mui/icons-material/Visibility";
import Upload from "@mui/icons-material/CloudUpload";
import { toast } from "react-hot-toast";
import CustomSearchBar from "../Elements/CustomSearchBar";
import CustomTable from "./CustomTable";
import LoanContractUploadContract from "../Modals/LoanContractUploadModal";
import ContractsFloatingActionMenu from "../Menus/ContractsFloatingActionMenu";
import CardWithIcon from "../Elements/CardWithIcon";
import { AddCircle } from "@mui/icons-material";

const ContractsTable = ({
	contractsSearchTerm,
	handleContractsSearchChange,
	isContractsLoading,
	filteredContractsData,
	setSelectedRow,
	setOpenUpdateContractModal,
	setPdfUrl,
	setOpenPdfDialog,
	refetch,
	selectedRow,
	selectedContracts,
	setSelectedContracts,
	page,
	totalItems,
	handleFetchNewPage,
	isRefetching,
	handleSize,
	handleSearch,
}) => {
	const theme = useTheme();

	const [openUploadDialog, setOpenUploadDialog] = useState(false);

	return (
		<Box position="relative" minHeight="100vh">
			<Box display="flex" alignItems="center" gap={2} my={2}>
				<Box sx={{ flexGrow: 1 }}>
					<CustomSearchBar
						searchTerm={contractsSearchTerm}
						onSearchChange={handleContractsSearchChange}
						handleSearch={handleSearch}
					/>
				</Box>

				<Box sx={{ marginLeft: "auto" }}>
					{selectedRow && (
						<Box sx={{ marginLeft: "auto" }}>
							<CardWithIcon
								icon={
									<AddCircle
										style={{
											color:
												theme.palette.mode == "light"
													? "rgba(73, 69, 79, 1)"
													: "white",
											marginRight: "5px",
											fontSize: "18px",
										}}
									/>
								}
								text="Update Status"
								onClick={() => {
									setOpenUpdateContractModal(true);
								}}
							/>
						</Box>
					)}
				</Box>
			</Box>

			<CustomTable
				isLoading={isContractsLoading || isRefetching}
				icons={(offer) => [
					{
						icon: (
							<Tooltip title="Update Contract" arrow>
								<Edit
									sx={{
										fill: theme.palette.mode === "light" ? "green" : "white",
										fontSize: "20px",
									}}
								/>
							</Tooltip>
						),
						onClick: () => {
							setSelectedRow(offer);
							setOpenUpdateContractModal(true);
						},
					},
					{
						icon: (
							<Tooltip title="View Signed Contract" arrow>
								<Visibility
									sx={{
										fill: theme.palette.mode === "light" ? "orange" : "white",
										fontSize: "20px",
									}}
								/>
							</Tooltip>
						),
						onClick: () => {
							setPdfUrl(offer?.downloadUrl);
							if (offer?.uploadedFileName) setOpenPdfDialog(true);
							else toast.error("Contract has not been uploaded.");
						},
					},
				]}
				columns={[
					{ title: "Email", key: "email", sortable: true },
					{
						title: "Product",
						key: "productType",
						sortable: true,
						isPill: true,
					},
					{ title: "Description", key: "description", sortable: true },
					{
						title: "Contract Sent",
						key: "contractSent",
						sortable: true,
						isPill: true,
					},
					{
						title: "Contract Signed",
						key: "contractSigned",
						sortable: true,
						isPill: true,
					},
					{ title: "Created", key: "createdTimestamp", sortable: true },
				]}
				data={filteredContractsData}
				actionColumn={{ title: "Actions" }}
				selectable
				onRowSelect={(rows) => {
					setSelectedContracts(rows);
					setSelectedRow(rows[rows ? rows.length - 1 : 0]);
				}}
				selected={selectedContracts}
				totalItems={totalItems || 0}
				handleNextPage={handleFetchNewPage}
				page={page}
				handleSize={handleSize}
			/>

			{selectedRow && (
				<ContractsFloatingActionMenu
					selectedRows={selectedContracts}
					data={selectedRow}
					refetch={refetch}
				/>
			)}
		</Box>
	);
};

export default ContractsTable;
